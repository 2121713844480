import React, { Component } from 'react';

export default class img extends Component {
  render() {
    return (
      <img
        ref={this.props.styleRef}
        src={this.props.src}
        alt={this.props.alt}
        className={this.props.className}
        style={this.props.style}
        id={this.props.id}
      />
    );
  }
}
