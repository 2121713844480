import { Checkbox } from 'semantic-ui-react';

const ThemedCheckbox = (props) => {
  return (
    <div style={{ marginTop: '.5em' }}>
      <Checkbox style={{ verticalAlign: 'middle' }} id="checkbox" {...props} label="" />
      <label htmlFor="checkbox" style={{ verticalAlign: 'middle', color: 'var(--font-color)' }}>
        {props.label}
      </label>
    </div>
  );
};

export default ThemedCheckbox;
