import Header from './Header';

const SubHeader = ({ style = {}, ...props }) => (
  <Header
    {...props}
    style={{
      marginTop: '0 !important',
      marginBottom: '0 !important',
      ...style,
    }}
  />
);

export default SubHeader;
