import React from 'react';
import { List } from 'semantic-ui-react';
import classNames from 'classnames';

import Column from './Column';

import FAB from './FAB';

import styles from './FABList.module.css';

const FABList = ({ data, current, label, color, header, onChange, ...props }) => {
  if (props.loading) {
    return <FAB color={color} label={label} size="3.8rem" {...props} />;
  }
  return (
    <FAB color={color} label={label} size="3.8rem" {...props}>
      <Column
        className={classNames(styles['list-wrapper'], {
          [styles['list-wrapper--chrome']]: window.navigator.userAgent.match(/Chrome/),
        })}
      >
        {header}
        {data.map((item) => (
          <List
            key={item.id}
            divided
            relaxed="very"
            size="large"
            style={{ backgroundColor: 'white', margin: 0, padding: '.85714286em' }}
          >
            <List.Item onClick={() => onChange(item.id)}>
              {item.icon && (
                <List.Icon name={item.icon} style={{ color: item.iconColor || 'black' }} />
              )}
              <List.Content
                style={{ '--color': color }}
                className={classNames(styles['option-item'], {
                  [styles['option-item--checked']]: item.id === current,
                })}
              >
                {item.label}
              </List.Content>
            </List.Item>
          </List>
        ))}
      </Column>
    </FAB>
  );
};

// FABList.propTypes = {
//   header: Component
//   data: [{id, label, icon?, iconColor?}],
//   onChange: (id) => {}
//   current,
//   label,
//   color,
// }

FABList.defaultProps = {
  data: [],
  onChange: () => {},
};

export default FABList;
