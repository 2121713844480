/* eslint no-mixed-operators:off */
/* eslint prefer-template:off */

// Specify localTimezone to false for stuff like birthdays
export default (inputFormat, localTimezone = true) => {
  let d = new Date(inputFormat);
  d = d.getTime() - (localTimezone ? d.getTimezoneOffset() * 60 * 1000 : 0);
  d = new Date(d);
  return (
    ('0' + d.getUTCDate()).slice(-2) +
    '/' +
    ('0' + (d.getUTCMonth() + 1)).slice(-2) +
    '/' +
    d.getUTCFullYear()
  );
};
