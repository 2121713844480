import React, { Component } from 'react';
import * as Showdown from 'showdown';
import ReactMde, { commands } from 'react-mde';
import { Label } from 'semantic-ui-react';

import './react-mde-all.css';

const listCommands = [
  {
    commands: [
      commands.headerCommand,
      commands.boldCommand,
      commands.italicCommand,
      commands.strikeThroughCommand,
    ],
  },
  {
    commands: [commands.linkCommand, commands.quoteCommand, commands.codeCommand],
  },
  {
    commands: [
      commands.unorderedListCommand,
      commands.orderedListCommand,
      commands.checkedListCommand,
    ],
  },
];

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
});

class MarkdownEdit extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: 'write', inputVal: '', error: false };
  }

  componentDidMount = () => {
    this.setState({ inputVal: this.props.content });
  };

  onChange = (data) => {
    const inputVal = data;
    if (this.props.checker && this.props.checker.parser) {
      this.setState({ error: !this.props.checker.parser(inputVal), inputVal });
    } else {
      this.setState({ inputVal });
    }

    if (!this.state.error) {
      this.props.onChange(inputVal);
    }
  };

  handleTabChange = (tab) => {
    this.setState({ tab });
  };

  render() {
    const id = `MarkdownEdit__${Math.random()}`;
    return (
      <div>
        {this.state.error && this.props.checker && this.props.checker.errorMessage && (
          <Label basic color="red" pointing="below" htmlFor={id}>
            {this.props.checker.errorMessage}
          </Label>
        )}
        <p
          style={{
            color: `${this.props.error || this.state.error ? '#9f3a38' : 'None'}`,
            fontSize: '0.93em',
            fontWeight: '700',
            marginBottom: '.29rem',
          }}
        >
          {this.props.title}
        </p>
        <ReactMde
          onChange={this.onChange}
          selectedTab={this.state.tab}
          onTabChange={this.handleTabChange}
          value={this.state.inputVal}
          generateMarkdownPreview={(markdown) => Promise.resolve(converter.makeHtml(markdown))}
          commands={listCommands}
        />
      </div>
    );
  }
}

export default MarkdownEdit;
