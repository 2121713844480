// Description :
// -> url config to ask front-proxy

import { getToken } from '%/utils';

const baseUrl = process.env.REACT_APP_FRONT_PROXY_URL;

const isMRMath = document.location.hostname.match(/([0-9a-z]+)\.mr\.preprod\.linkcs\.fr/);

const portMatch = window.location.host.match(/localhost:([0-9]+)/);
const localPort = portMatch ? portMatch[1] : '';

const portString = localPort === '' ? '' : `?port=${localPort}`;
const portRefresh = portString.replace('?', '&');

const querystringFromMatch = isMRMath === null ? portString : `?origin=${isMRMath[1]}`;

export const loginUrl = `${baseUrl}login${querystringFromMatch}`;

export const refreshLoginUrl = `${baseUrl}refresh?token=${getToken()}${portRefresh}`;
