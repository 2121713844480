import SanitizeDiv from './sanitize/SanitizeDiv';

const aligns = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

const positions = {
  top: 'flex-end',
  middle: 'center',
  bottom: 'flex-start',
};

const Line = ({ style = {}, fluid, align = 'left', position = 'top', ...props }) => (
  <SanitizeDiv
    {...props}
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: positions[position],
      justifyContent: aligns[align],
      width: fluid ? '100%' : 'auto',
      ...style,
    }}
  />
);

export default Line;
