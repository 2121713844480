// Description :
// -> Components used for profilePage

// React requirement
import React from 'react';

// General
import { LoadingBar, SingleItem, Row } from '%/core';

/*
iconChild prop:
  If set to true, the icons will come from the children and not from the parent,
  so that you can specify an icon for each item separately.
  In this case, you don't need to use the name prop anymore,
  and the data has to be given with the following form:
  [
    {
      icon: "telegram",
      data: "sjfgfdg",
    },
    ...
  ]
*/

const MultiItem = ({ data, loading, name, iconChild }) =>
  loading ? (
    <LoadingBar
      style={{
        marginBottom: '0.5em',
        paddingTop: 0,
      }}
    />
  ) : (
    <React.Fragment>
      {data.map((item) => (
        <div style={{ display: 'flex', alignItems: 'center' }} key={item.key}>
          <Row>
            <SingleItem name={iconChild ? item.icon : name} data={iconChild ? item.data : item} />
          </Row>
        </div>
      ))}
    </React.Fragment>
  );

export default MultiItem;
