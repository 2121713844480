// Description :
// -> apollo function to fetch user data

// React requirement
import React from 'react';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

// Fragments requirement
import { includeFragment } from '%/utils';
import * as fragments from '%/fragments';

const phoneNumberRegex = /^(\+|[0-9]|-|\s|\.)+$/;

const withUserTerm = (term, limit) => {
  const searchUser = gql`
    query {
      searchUser: searchUsers(
        name: "${term}",
        login: "${term}",
        nickname: "${term}",
        plateNumber: "${term}",
        roomNumber: "${term}",
        ${phoneNumberRegex.test(term) ? `phoneNumber: "${term}",` : ''}
        limit: ${limit},
      ) {
        user {
          ...userData
        }
        field
      }
    }
  `;
  includeFragment(searchUser, fragments.userData);
  return graphql(searchUser);
};

const apolloGetSearchUser = (WrappedComponent) => {
  const ApolloComponent = ({ termSearch, limit, ...props }) => {
    if (termSearch) {
      const OutputComponent = withUserTerm(termSearch, limit)(WrappedComponent);
      return <OutputComponent {...props} />;
    }
    return <WrappedComponent {...props} data={{}} />;
  };
  return ApolloComponent;
};

export default apolloGetSearchUser;
