import React, { Component } from 'react';

import { getToken } from '%/utils';
import { Photo, Cropper } from '%/core';

import { Modal, Form, Input, Button, Icon, Message } from 'semantic-ui-react';

class ImageUpload extends Component {
  state = { file: '', imagePreviewUrl: '', error: '' };

  handleImageChange = (event) => {
    event.preventDefault();

    const reader = new FileReader();
    const file = event.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData();

    data.append('file', this.state.file);
    const token = getToken();

    fetch(`${process.env.REACT_APP_CDN_POST_URL}`, {
      method: 'POST',
      headers: {
        Authorization: token ? `Bearer ${token}` : null,
      },
      body: data,
    })
      .then((res) =>
        res.ok
          ? res.text()
          : this.handleError(`Une erreur est survenue : ${res.status} - ${res.statusText}`),
      )
      .catch((error) => this.handleError(`Une erreur est survenue : ${error}`))
      .then(this.props.handleSubmit);
  };

  handleError = (error) => {
    this.setState({ error });

    return Promise.reject(error);
  };

  cropSent = (croppedImage) => {
    // On récupère l'image croppée
    this.setState({ file: croppedImage });
  };

  render() {
    const { imagePreviewUrl } = this.state;
    let ImagePreview = null;
    let square = this.props.square;
    if (imagePreviewUrl && (!this.props.cropper || this.state.file.type === 'image/gif')) {
      ImagePreview = (
        <Photo
          centered
          circular={this.props.circular}
          ratio={this.props.ratio}
          src={imagePreviewUrl}
          alt="preview"
          maxWidth="50%"
        />
      );
    } else if (imagePreviewUrl) {
      ImagePreview = (
        <div style={{ justifyContent: 'center' }}>
          <Cropper
            square={square}
            src={imagePreviewUrl}
            changeParentFile={this.cropSent}
            style={{ maxWidth: '50%' }}
          />
        </div>
      );
    }

    return (
      <Modal
        open={this.props.open}
        onClose={() => this.props.handleSubmit('')}
        closeOnEscape
        closeOnRootNodeClick
        // Close when we click outside is annoying with cropper.
        closeOnDimmerClick={!this.props.cropper}
        closeIcon
        style={{ width: '60vw', minHeight: '50vh', padding: '2em' }}
      >
        <Form onSubmit={this.handleSubmit}>
          <Form.Group>
            <label htmlFor="fileImage">
              <span className="ui button">
                <Icon name="file" />
                {this.state.file ? 'Choisir une autre photo' : 'Choisir une photo'}
              </span>
            </label>
            <Input
              id="fileImage"
              type="file"
              accept="image/png, image/jpeg, image/gif"
              style={{ display: 'none' }}
              onChange={this.handleImageChange}
            />

            <Button positive type="submit" disabled={!this.state.file} onClick={this.handleSubmit}>
              <Icon name="upload" />
              Importer la photo
            </Button>
          </Form.Group>
          <p style={{ textAlign: 'center', marginTop: '40px' }}>
            L&rsquo;image ne doit pas dépasser 1 Mo
          </p>
          {this.state.error !== '' && <Message error header="Erreur" content={this.state.error} />}
        </Form>
        <div style={{ textAlign: 'center' }}>{ImagePreview}</div>
      </Modal>
    );
  }
}

ImageUpload.defaultProps = {
  open: false,
  circular: false,
  ratio: null,
  handleSubmit: () => {},
  cropper: false,
  square: false,
};

export default ImageUpload;
