// Description :
// -> Defines the component Navbar

import { Line } from '%/core';

const Bar = (props) => (
  <Line
    {...props}
    style={{
      backgroundColor: 'var(--primary)',
      color: '#ffffff',
      padding: '0 0.5em',
      position: 'fixed',
      top: 0,
      width: '100vw',
      zIndex: '2000',
      boxShadow: '0px 1px 5px 0px hsla(0, 0%, 0%, 0.5)',
    }}
  />
);

export default Bar;
