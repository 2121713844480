import { useContext } from 'react';
import { Menu } from 'semantic-ui-react';
import { DarkThemeContext } from '%/core';

const ColoredMenu = (props) => {
  const [darktheme] = useContext(DarkThemeContext);
  return (
    <Menu inverted={darktheme === 'dark'} {...props}>
      {props.children}
    </Menu>
  );
};

export default ColoredMenu;
