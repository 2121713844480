import React from 'react';
import { Only } from '@blocz/react-responsive';

import GridItemPhoto from '../grid/GridItemPhoto';
import ListItemPhoto from '../list/ListItemPhoto';

const convertToRoles = (array) => {
  const roles = new Array(array.length * 2 + 1).fill(null);
  for (let index = 0; index < array.length; index += 1) {
    roles[index * 2] = <span title={array[index]}>{array[index]}</span>;
  }
  for (let index = 0; index < array.length - 1; index += 1) {
    roles[index * 2 + 1] = ' • ';
  }
  return roles;
};

const GridListItemPhoto = ({ styled, ...props }) => {
  if (styled === 'grid') {
    return GridItemPhoto(props);
  }
  if (styled === 'list') {
    return ListItemPhoto(props);
  }
  const p = {
    ...props,
    content:
      Array.isArray(props.content) && !React.isValidElement(props.content[0])
        ? convertToRoles(props.content)
        : props.content,
  };
  return (
    <React.Fragment>
      <Only {...p} on="smDown" as={ListItemPhoto} />
      <Only {...p} on="mdUp" as={GridItemPhoto} />
    </React.Fragment>
  );
};

export default GridListItemPhoto;
