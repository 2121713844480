import React, { Component } from 'react';
import { createPortal } from 'react-dom';

import classNames from 'classnames';

import styles from './FAB.module.css';

const duration = 250;

const FABElement = ({ style, opened, children, fabRef, className, onClick }) => {
  if (opened) {
    return (
      <div className={className} style={style} ref={fabRef}>
        {children}
      </div>
    );
  }
  return (
    <button className={className} style={style} onClick={onClick} ref={fabRef} type="button">
      {children}
    </button>
  );
};

class FAB extends Component {
  state = { opened: false };

  componentWillUpdate(nextProps, nextState) {
    if (nextState.opened !== this.state.opened) {
      if (nextState.opened) {
        if (nextProps.autoClose) {
          this.addEventListener();
        }
      } else {
        this.removeEventListener();
      }
    }
  }

  componentWillUnmount() {
    this.removeEventListener();
  }

  onWindowClick = (event) => {
    if (this.fab && !this.fab.contains(event.target)) {
      if (event.cancelable) {
        event.preventDefault();
      }
      this.close();
    }
  };

  addEventListener = () => {
    window.addEventListener('touchend', this.onWindowClick);
    window.addEventListener('mouseup', this.onWindowClick);
  };

  removeEventListener = () => {
    window.removeEventListener('touchend', this.onWindowClick);
    window.removeEventListener('mouseup', this.onWindowClick);
  };

  open = (event) => {
    if (this.props.children) {
      if (this.state.opened) {
        event.preventDefault();
      } else {
        this.setState({ opened: true }, () => {
          this.updateSize({ maxHeight: '0px', maxWidth: '0px' });
          this.updateSize();
        });
      }
    }
  };

  close = () => {
    this.updateSize({ maxHeight: '0px', maxWidth: '0px' });
    setTimeout(() => {
      this.setState({ opened: false }, () => this.updateSize());
    }, duration);
  };

  computeSize = () => ({
    maxWidth: `${this.fab.scrollWidth + 7}px`,
    maxHeight: `${this.fab.scrollHeight + 7}px`,
  });

  updateSize = (size) => {
    if (!this.fab) {
      return;
    }
    const { maxWidth, maxHeight } = size || this.computeSize();

    this.fab.style.maxHeight = maxHeight;
    this.fab.style.maxWidth = maxWidth;
    this.prevSize = { maxWidth, maxHeight };
  };

  prevSize = { maxHeight: '0px', maxWidth: '0px' };

  render() {
    const {
      size,
      color,
      textColor,
      onClick,
      label,
      position,
      spacing,
      bottom,
      right,
      zIndex,
      loading,
      style,
      autoClose,
      ...props
    } = this.props;
    let { children } = props;

    // Inject closeFab = this.close if children is a react element
    if (
      !loading &&
      React.isValidElement(children) &&
      !(Object.keys(children.props).length === 1 && children.props.children)
    ) {
      children = { ...children };
      const newProps = { ...children.props };
      if (!autoClose) {
        newProps.closeFab = this.close;
      }
      newProps.updateSize = this.updateSize;
      // Freeze objects
      children.props = Object.freeze(newProps);
      children = Object.freeze(children);
    }

    const display = (
      <FABElement
        fabRef={(node) => {
          this.fab = node;
        }}
        className={classNames(styles.fab, {
          [styles['fab--opened']]: this.state.opened,
          [styles['fab--loading']]: loading,
        })}
        style={{
          '--size': size,
          '--color': color,
          '--text-color': textColor,
          '--position': position,
          '--spacing': spacing,
          '--bottom': bottom,
          '--right': right,
          '--duration': duration,
          ...style,
        }}
        onClick={children ? this.open : onClick}
        opened={this.state.opened}
      >
        {!loading && this.state.opened ? children : label}
      </FABElement>
    );

    return createPortal(display, document.body);
  }
}

FAB.defaultProps = {
  size: '3em',
  textColor: 'white',
  position: 0,
  spacing: '0.7em',
  bottom: '1.4em',
  right: '1.4em',
  autoClose: true,
};

export default FAB;
