import React, { Component } from 'react';

export default class div extends Component {
  render() {
    const props = {
      id: this.props.id,
      className: this.props.className,
      style: this.props.style,
      role: this.props.role,
    };
    if (this.props.onClick) {
      props.onClick = this.props.onClick;
      props.role = 'button';
    }
    return (
      <div {...props} ref={this.props.styleRef}>
        {this.props.children}
      </div>
    );
  }
}
