import { Grid } from 'semantic-ui-react';

const Row = ({ style = {}, ...props }) => (
  <Grid.Row
    {...props}
    style={{
      paddingTop: '0.25em',
      paddingBottom: '0.25em',
      ...style,
    }}
  />
);

export default Row;
