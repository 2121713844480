import React from 'react';

import { Icon } from 'semantic-ui-react';

const ToggleButton = (props) => (
  <span style={{ display: 'inline', marginLeft: '0.5em', float: 'right' }}>
    <Icon name={props.active ? 'triangle up' : 'triangle down'} size="small" />
  </span>
);

export default ToggleButton;
