// Description :
// -> The SideBar component make a sidebar

// React requirement
import React, { Fragment } from 'react';
import { Only } from '@blocz/react-responsive';
import { Grid } from 'semantic-ui-react';

const SideBar = (props) => {
  let sectionWidth = 16;
  if (props.right) {
    sectionWidth -= props.rightWidth;
  }
  if (props.left) {
    sectionWidth -= props.leftWidth;
  }
  return (
    <Fragment>
      <Only on="smDown">
        {props.left && <Fragment>{props.left}</Fragment>}
        {props.right && <Fragment>{props.right}</Fragment>}
        <Fragment>{props.children}</Fragment>
      </Only>

      <Only on="mdUp" as={Grid}>
        {props.left && <Grid.Column width={props.leftWidth}>{props.left}</Grid.Column>}
        <Grid.Column width={sectionWidth}>{props.children}</Grid.Column>
        {props.right && <Grid.Column width={props.rightWidth}>{props.right}</Grid.Column>}
      </Only>
    </Fragment>
  );
};

SideBar.defaultProps = {
  leftWidth: 4,
  rightWidth: 4,
};

export default SideBar;
