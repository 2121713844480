import { useBreakpoint } from '@blocz/react-responsive';
import { useRef } from 'react';

export const useDynamicStyles = (dynamicStyles) => {
  // The object can never change, otherwise it'll break the rule of hooks
  // So we fix the styles during the 1st render
  const fixStyles = useRef(dynamicStyles).current;

  const styles = {};

  Object.entries(fixStyles).forEach(([breakpoint, breakpointStyles]) => {
    // I know that this isn't good, but as long as the
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const isApplied = useBreakpoint(breakpoint);
    if (isApplied) {
      Object.assign(styles, breakpointStyles);
    }
  });

  return styles;
};
