// This file exports a function that validate the creation of an association
//   -> if the event is not valid, fill it with its errors and returns it
//   -> else if the event is valid, it can be sent to the back, and returns null

// React requirement
const verifyDateFromat = (date) => {
  if (date.length === 0) {
    return true;
  }
  if (date.length === 16) {
    const year = date.substr(0, 4);
    const month = date.substr(5, 2);
    const day = date.substr(8, 2);
    const hours = date.substr(11, 2);
    const minutes = date.substr(14, 2);
    const substring = [...year, ...month, ...day, ...hours, ...minutes];
    let validate = true;
    substring.forEach((char) => {
      if (!Number.isInteger(parseInt(char, 10))) {
        validate = false;
      }
    });
    if (!validate) {
      return false;
    }
    const monthInt = parseInt(month, 10);
    const dayInt = parseInt(day, 10);
    const hoursInt = parseInt(hours, 10);
    const minutesInt = parseInt(minutes, 10);
    if (monthInt < 1 || monthInt > 12) {
      return false;
    }
    if (dayInt < 1 || dayInt > 31) {
      return false;
    }
    if (hoursInt < 0 || hoursInt > 23) {
      return false;
    }
    if (minutesInt < 0 || minutesInt > 59) {
      return false;
    }
    return true;
  }
  return false;
};
const convertToDateFormat = (date) => {
  const year = parseInt(date.substr(0, 4));
  const month = parseInt(date.substr(5, 2)) - 1;
  const day = parseInt(date.substr(8, 2));
  const hours = parseInt(date.substr(11, 2));
  const minutes = parseInt(date.substr(14, 2));
  const dateFormat = new Date(year, month, day, hours, minutes);
  return dateFormat;
};

export default (event, newEvent, isAuthorizedUser) => {
  let validate = true;
  const correction = { ...event };
  const { title, location, description, beginningDate, endDate, association } = correction;
  correction.errors = [];
  if (title === '') {
    correction.errors.push({
      name: 'title',
      message: "Le titre de l'évènement doit être renseigné",
    });
  }
  if (newEvent && !Number.isInteger(association)) {
    correction.errors.push({
      name: 'association',
      message: 'Vous devez sélectionner une association',
    });
  }
  if (newEvent && !isAuthorizedUser) {
    correction.errors.push({
      name: 'association',
      message: 'Vous ne possédez pas le droit de publier au nom de cette association',
    });
  }
  if (beginningDate.length === 0) {
    correction.errors.push({
      name: 'beginningDate',
      message: "La date de début de l'évènement doit être renseignée",
    });
  }
  if (endDate.length === 0) {
    correction.errors.push({
      name: 'endDate',
      message: "La date de fin de l'évènement doit être renseignée",
    });
  }
  if (!verifyDateFromat(beginningDate)) {
    correction.errors.push({
      name: 'beginningDate',
      message: 'La date de début doit être renseignée en respectant le format JJ/MM/AAAA hh:mm',
    });
  }
  if (!verifyDateFromat(endDate)) {
    correction.errors.push({
      name: 'endDate',
      message: 'La date de fin doit être renseignée en respectant le format JJ/MM/AAAA hh:mm',
    });
  } else if (endDate.length !== 0) {
    const begin = convertToDateFormat(beginningDate).getTime();
    const end = convertToDateFormat(endDate).getTime();
    if (begin > end) {
      correction.errors.push({
        name: 'endDate',
        message: 'La date de fin doit être située après la date de début',
      });
    }
  }
  if (location.length === 0) {
    correction.errors.push({
      name: 'location',
      message: "L'emplacement de l'évènement doit être renseigné",
    });
  }
  if (description.length === 0) {
    correction.errors.push({
      name: 'description',
      message: "Vous devez décrire l'évènement",
    });
  }
  validate = validate && correction.errors.length === 0;
  return validate ? null : { ...correction, validate };
};
