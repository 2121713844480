// Description :
// -> The Main component is the website once logged in

// React requirement
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Only } from '@blocz/react-responsive';

import { Container } from '%/core';

// Local requirement
import { NavBar, NavBarMobile } from './NavBar'; // Wrapper for navigation
import { Footer, FooterMobile } from './Footer';

const Main = (props) => {
  useEffect(() => {
    let screen = document.getElementById('blackout');
    const callback = (e) => {
      screen.style.setProperty('--x', e.clientX + 'px');
      screen.style.setProperty('--y', e.clientY + 'px');
    };
    screen.addEventListener('mousemove', callback);
    return () => screen.removeEventListener('mousemove', callback);
  }, []);

  return (
    <React.Fragment>
      <Only on="mdUp">
        <NavBar />
        <Container
          style={{
            fontSize: 'var(--f3)',
            marginTop: '32px',
            marginBottom: '2em',
          }}
        >
          {props.children}
        </Container>
        {/* The footer has to be out of the #root div, so that it's placed at the bottom of the page : */}
        {ReactDOM.createPortal(<Footer />, document.body)}
      </Only>
      <Only on="smDown">
        <NavBarMobile>
          <Container
            id="main-mobile-container"
            style={{
              fontSize: 'var(--f3)',
              marginTop: '10px',
              marginBottom: '2em',
              minHeight: '100vh',
            }}
          >
            {props.children}
          </Container>
        </NavBarMobile>
        <FooterMobile />
      </Only>
    </React.Fragment>
  );
};

export default Main;
