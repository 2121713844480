// Description :
// -> Defines the component Logout

// React requirement
import React from 'react';

import { clearToken } from '%/utils';

const Logout = () => {
  clearToken();
  window.location.replace(process.env.REACT_APP_LOGOUT_URL);
  return <div />;
};

export default Logout;
