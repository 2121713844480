import React, { useState, useEffect, useRef } from 'react';
import { useBreakpoint } from '@blocz/react-responsive';

import Container from '../display/Container';

const StickToTop = (props) => {
  const [fixed, setFixed] = useState(false);
  const [initialOffsetTop, setInitialOffsetTop] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);

  const element = useRef(null);

  const matchMdUp = useBreakpoint('mdUp');

  const getScrollbarWidth = () => window.innerWidth - document.documentElement.clientWidth;

  useEffect(() => {
    const updateInitialOffset = () => {
      setInitialOffsetTop(element.current.offsetTop - props.offsetY);
      setClientHeight(element.current.clientHeight);
    };

    const onScroll = () => {
      if (!fixed) {
        updateInitialOffset();
      }
      setFixed(window.scrollY > initialOffsetTop);
    };

    window.addEventListener('scroll', onScroll, false);
    if (!fixed) {
      updateInitialOffset();
    }

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [props.offsetY, fixed, initialOffsetTop]);

  return [
    <div
      key="controlBar"
      ref={element}
      style={{
        position: fixed && matchMdUp ? 'fixed' : 'relative',
        top: fixed && matchMdUp ? `${props.offsetY}px` : 0,
        zIndex: props.zIndex,
        left: fixed && matchMdUp ? getScrollbarWidth() / 2 : 0,
        width: '100%',
      }}
    >
      <Container>{props.component({ stuck: fixed })}</Container>
    </div>,
    <div key="space" style={{ height: fixed ? `${clientHeight}px` : 0 }} />,
  ];
};

StickToTop.defaultProps = {
  offsetY: 0,
  zIndex: 2,
  component: () => {},
};

export default StickToTop;
