import React, { useContext } from 'react';
import { DarkThemeContext } from '%/core';
import { Button } from 'semantic-ui-react';

const ContextButton = (props) => {
  const [darktheme] = useContext(DarkThemeContext);
  return (
    <Button inverted={darktheme === 'dark'} {...props}>
      {props.children}
    </Button>
  );
};

export default ContextButton;
