import { List } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { Photo, LoadingBar, Line } from '%/core';

const LinkDuo = ({ to, children, ...props }) => {
  if (/^https?:\/\//.test(to)) {
    return (
      <a href={to} {...props}>
        {children}
      </a>
    );
  }
  return (
    <Link to={to} {...props}>
      {children}
    </Link>
  );
};

const ListItemPhoto = ({
  to,
  src,
  header,
  content,
  loading,
  targetBlank,
  maxWidth,
  width,
  minWidth,
  customSize,
}) => (
  <List.Item as={LinkDuo} to={loading ? '#' : to} target={!targetBlank ? '' : '_blank'}>
    <Line position="middle" style={{ paddingBottom: '0.1em', paddingTop: '0.1em' }}>
      <Photo
        src={loading ? `${process.env.PUBLIC_URL}/images/placeholder.png` : src}
        fluid
        rounded
        ratio={1}
        style={
          customSize
            ? { container: { maxWidth, width, minWidth } }
            : { container: { maxWidth: '7.5rem', width: '25%', minWidth: '25%' } }
        }
      />
      <List.Content
        style={{
          fontSize: '14px',
          flex: 1,
          paddingLeft: '0.8em',
          maxWidth: '100%',
        }}
      >
        <List.Header style={{ fontSize: '1.1em', color: 'var(--font-color)' }}>
          {loading ? (
            <LoadingBar style={{ marginBottom: '0.25em', width: '7.5em', lineHeight: 1.25 }} />
          ) : (
            header
          )}
        </List.Header>
        <List.Description
          style={{
            paddingTop: '.3em',
            paddingRight: '1em',
            overflowWrap: 'break-word',
            color: 'var(--font-color)',
          }}
        >
          {loading ? <LoadingBar style={{ width: '10em', lineHeight: 1.25 }} /> : content}
        </List.Description>
      </List.Content>
    </Line>
  </List.Item>
);

export default ListItemPhoto;
