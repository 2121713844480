import React, { useContext, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import DarkThemeContext from '../theme/DarkTheme/DarkThemeContext';

function NavBarDropdown(props) {
  const [isOpen, updateIsOpen] = useState(false);
  const [darktheme] = useContext(DarkThemeContext);
  // For the last Dropdown, options aren't used because of the toggler, it has to be handmade for this case
  if (props.options) {
    const items = [];
    for (const itemOption of props.options) {
      items.push(<Dropdown.Item {...itemOption} style={{ color: 'var(--font-color)' }} />);
    }
    return (
      <Dropdown
        {...props}
        onMouseOver={() => updateIsOpen(true)}
        onMouseLeave={() => updateIsOpen(false)}
        onFocus={() => updateIsOpen(true)}
        onBlur={() => updateIsOpen(false)}
        open={isOpen}
      >
        <Dropdown.Menu style={{ backgroundColor: darktheme === 'dark' ? '#505050' : 'white' }}>
          {items}
        </Dropdown.Menu>
      </Dropdown>
    );
  } else {
    return (
      <Dropdown
        {...props}
        onMouseOver={() => updateIsOpen(true)}
        onMouseLeave={() => updateIsOpen(false)}
        onFocus={() => updateIsOpen(true)}
        onBlur={() => updateIsOpen(false)}
        open={isOpen}
      />
    );
  }
}

NavBarDropdown.Item = Dropdown.Item;
NavBarDropdown.Menu = Dropdown.Menu;

export default NavBarDropdown;
