// Description :
// -> Defines the component Navitem

// React requirement
import React from 'react';

import { Line } from '%/core';

const Wrapper = ({ style = {}, ...props }) => (
  <Line
    {...props}
    style={{
      padding: '0.8em',
      alignItems: 'center',
      ...style,
    }}
  />
);

const Navitem = ({ onClick, children, style }) => (
  <Wrapper onClick={onClick} style={style}>
    {children}
  </Wrapper>
);

Navitem.defaultProps = {
  onClick: () => {},
  children: () => {},
  style: {},
};

export default Navitem;
