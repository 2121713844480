import { Icon } from 'semantic-ui-react';

const SubIcon = ({ style = {}, ...props }) => (
  <Icon
    {...props}
    style={{
      marginRight: '0.6rem !important',
      ...style,
    }}
  />
);

export default SubIcon;
