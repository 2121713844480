import React, { useState, useEffect, useContext } from 'react';
import { Button, Icon, Loader } from 'semantic-ui-react';
import { DarkThemeContext } from '%/core';

export default ({ eventId, tile, ...props }) => {
  const [darktheme] = useContext(DarkThemeContext);
  const buttonColor = darktheme === 'dark' ? 'grey' : 'white';
  const [liked, setLiked] = useState(false);
  const [likedId, setLikedId] = useState(null);
  const [going, setGoing] = useState(false);
  const [likeCount, setLikeCount] = useState(-1);
  const [goingCount, setGoingCount] = useState(-1);

  const [initializing, setInitializing] = useState(true);
  const [waitingForRequest, setWaitingForRequest] = useState(false);

  useEffect(() => {
    if (initializing && !props.likes.loading && props.isLiked && !props.isLiked.loading) {
      setInitializing(false);
      setLikeCount(props.likes.searchLike.length);
      setGoingCount(props.likes.searchLike.filter((e) => e.going).length);

      if (props.isLiked.searchLike.length !== 0) {
        const myLike = props.isLiked.searchLike[0];

        setLiked(true);
        setLikedId(myLike.id);
        setGoing(myLike.going);
      } else {
        setLiked(false);
        setLikedId(null);
        setGoing(null);
      }
    }
  });

  // Interested
  const handleInterestedClick = (e) => {
    e.preventDefault();

    if (liked && likedId && !waitingForRequest) {
      const going_ = going;
      setWaitingForRequest(true);

      props.deleteLike(likedId).then(({ data: { deleteLike } }) => {
        if (deleteLike === null) {
          setLiked(false);
          setLikedId(null);
          setGoing(false);
          setLikeCount(likeCount - 1);
          setGoingCount(goingCount - (going_ ? 1 : 0));
          setWaitingForRequest(false);
        }
      });
    } else if (!liked && !waitingForRequest) {
      setWaitingForRequest(true);

      props.createLike({ event: eventId, going: false }).then(({ data: { createLike } }) => {
        if (createLike.id) {
          setLiked(true);
          setLikedId(createLike.id);
          setGoing(createLike.going);
          setLikeCount(likeCount + 1);
          setWaitingForRequest(false);
        }
      });
    }
  };

  const handleGoingClick = (e) => {
    e.preventDefault();

    if (liked && likedId && !waitingForRequest) {
      setWaitingForRequest(true);

      props.updateLike(likedId, { going: !going }).then(({ data: { updateLike } }) => {
        if (updateLike.id) {
          setGoing(updateLike.going);
          setGoingCount(goingCount + (updateLike.going ? 1 : -1));
          setWaitingForRequest(false);
        }
      });
    } else if (!liked && !waitingForRequest) {
      setWaitingForRequest(true);

      props.createLike({ event: eventId, going: true }).then(({ data: { createLike } }) => {
        if (createLike.id) {
          setLiked(true);
          setLikedId(createLike.id);
          setGoing(createLike.going);
          setLikeCount(likeCount + 1);
          setGoingCount(goingCount + 1);
          setWaitingForRequest(false);
        }
      });
    }
  };

  const likedName = `heart${liked ? '' : ' outline'}`;
  const goingName = `hand peace${going ? '' : ' outline'}`;

  if (initializing) return null;

  const iconGO = <Icon name={goingName} style={{ margin: 0 }} color="yellow" />;
  const iconNI = <Icon name={likedName} style={{ margin: '0' }} color="red" />;

  // Tile on Home Page
  if (tile) {
    return (
      <>
        <Icon
          title={`J'aime (${likeCount})`}
          name={likedName}
          style={{ margin: 0, float: 'left' }}
          onClick={handleInterestedClick}
        />
        <Icon
          title={`J'y vais (${goingCount})`}
          name={goingName}
          style={{ margin: 0, float: 'right' }}
          onClick={handleGoingClick}
        />
      </>
    );
  }

  // Actuality page
  return (
    <>
      <Button.Group style={{ borderColor: 'black', borderWidth: '1px' }}>
        <Button
          basic={darktheme === 'light'}
          color={buttonColor}
          icon
          labelPosition="left"
          onClick={handleInterestedClick}
        >
          J{"'"}aime ({likeCount}) {iconNI}
        </Button>
        <Button
          basic={darktheme === 'light'}
          color={buttonColor}
          icon
          labelPosition="left"
          onClick={handleGoingClick}
        >
          J{"'"}y vais ({goingCount}) {iconGO}
        </Button>
      </Button.Group>
      {waitingForRequest ? (
        <span style={{ marginLeft: '.2em' }}>
          <Loader active inline size="tiny" />
        </span>
      ) : null}
    </>
  );
};
