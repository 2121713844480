import React, { PureComponent } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop from 'react-image-crop';

class Cropper extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      crop: null,
      image: null,
      isLoaded: false, // Existe car sinon l'asynchrone casse tout
    };
  }

  onLoaded = (image) => {
    const defaultDimension = ((image.width < image.height ? image.width : image.height) * 90) / 100;
    const crop = {
      x: (image.width - defaultDimension) / 2,
      y: (image.height - defaultDimension) / 2,
      width: defaultDimension,
      height: defaultDimension,
      aspect: this.props.square ? 1 : '',
    };
    this.setState({ isLoaded: false }, () => {
      this.setState(
        {
          image,
          crop,
        },
        () => {
          this.setState({ isLoaded: true });
          this.getCroppedImg(this.state.image, this.state.crop);
        },
      );
    });
  };

  onCropChange = (crop) => {
    if (this.state.isLoaded) {
      this.setState({ crop });
    }
  };

  onCropComplete = (crop) => {
    if (this.state.image && this.state.crop) {
      this.getCroppedImg(this.state.image, crop);
    }
  };

  /**
   * This let pass from the crop to a cropped image
   * @param {File} image - Image File Object
   * @param {Object} crop - crop Object
   * @param {String} fileName - Name of the returned file in Promise
   */
  getCroppedImg = async (image, crop) => {
    // Si on ne travaille pas sur ça mais sur image, On aura une version miniature du crop
    const goodDimImage = new Image(image.naturalWidth, image.naturalHeight);
    goodDimImage.src = this.props.src;

    const canvas = document.createElement('canvas');

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;

    const ctx = canvas.getContext('2d');

    const fileType = image.currentSrc.split(';')[0].split('/')[1];

    ctx.drawImage(
      goodDimImage,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );
    canvas.toBlob(
      (blob) =>
        this.props.changeParentFile(
          new File([blob], `croppedImage.${fileType}`, { type: `image/${fileType}` }),
        ),
      `image/${fileType}`,
    );
  };

  render() {
    return (
      <ReactCrop
        src={this.props.src}
        crop={this.state.crop}
        onImageLoaded={this.onLoaded}
        onChange={this.onCropChange}
        onComplete={this.onCropComplete}
        minWidth={1} // Il fallait bien choisir
        keepSelection
        style={this.props.style}
      />
    );
  }
}
export default Cropper;
