import { useContext } from 'react';
import DarkThemeContext from './DarkThemeContext';
import { Checkbox, Icon } from 'semantic-ui-react';

const Toggler = () => {
  const [darktheme, setTheme] = useContext(DarkThemeContext);
  const toggle = () => {
    const newTheme = darktheme === 'dark' ? 'light' : 'dark';
    localStorage.setItem('theme', newTheme);
    document.body.classList.remove(darktheme);
    document.body.classList.add(newTheme);
    setTheme(newTheme);
  };
  return (
    <div onClick={toggle}>
      <span>
        <Icon name="moon" style={{ margin: '0 0.8em 0 0' }} />
        Mode sombre
      </span>
      <Checkbox
        toggle
        checked={darktheme === 'dark'}
        style={{ verticalAlign: 'middle', margin: '0 0 0 1em' }}
      />
    </div>
  );
};

export default Toggler;
