// -> Defines the component ButtonAdd utilisé dans la home page pour les actualités et news

import React, { useContext } from 'react';
import { Icon, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { DarkThemeContext } from '%/core';

const ButtonAdd = ({ addLink }) => {
  const [darktheme] = useContext(DarkThemeContext);
  return (
    <Link to={addLink}>
      <Button inverted={darktheme === 'dark'} compact floated="right">
        <Icon name="plus" />
        Nouveau
      </Button>
    </Link>
  );
};

export default ButtonAdd;
