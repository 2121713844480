import { useState } from 'react';
import DarkThemeContext from './DarkThemeContext';

const DarkThemeProvider = (props) => {
  // Using a lazy initial state so that it's computed only on first render
  // https://dev.to/ayc0/light-dark-mode-react-implementation-3aoa#initialization-of-the-mode
  const [theme, setTheme] = useState(
    () =>
      localStorage.getItem('theme') ||
      (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light') ||
      'light',
  );
  return (
    <DarkThemeContext.Provider value={[theme, setTheme]}>
      {props.children}
    </DarkThemeContext.Provider>
  );
};

export default DarkThemeProvider;
