// Description :
// -> Defines the component ButtonDelete

// React requirement
import React from 'react';
import { Icon } from 'semantic-ui-react';

import { Button } from '%/core';

const icon = <Icon name="trash" style={{ margin: 0 }} />;

const ButtonDelete = ({ onClick }) => {
  return (
    <Button icon circular onClick={onClick}>
      {icon}
    </Button>
  );
};

export default ButtonDelete;
