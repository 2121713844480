// Description :
// -> Defines the component Title

// React requirement

import { SanitizeDiv } from '%/core';
import classNames from 'classnames';

import { title } from './Title.module.css';

const Title = (props) => <SanitizeDiv {...props} className={classNames(props.className, title)} />;

export default Title;
