import React, { Component } from 'react';

import { Button } from 'semantic-ui-react';

class Slide extends Component {
  constructor(props) {
    super(props);
    this.state = { index: 0 };
    this.nextSlide = () => {
      const nSlides = this.props.data.length;
      this.setState((state) => ({ index: (((state.index + 1) % nSlides) + nSlides) % nSlides }));
    };
    this.previousSlide = () => {
      const nSlides = this.props.data.length;
      this.setState((state) => ({ index: (((state.index - 1) % nSlides) + nSlides) % nSlides }));
    };
  }

  render() {
    return (
      <div style={{ width: '100%', height: '100%', position: 'relative' }}>
        {this.props.data[this.state.index]}
        <Button
          circular
          size="tiny"
          compact
          icon="angle left"
          onClick={this.previousSlide}
          style={{
            float: 'left',
            position: 'absolute',
            left: '0px',
            top: '50%',
            transform: 'translateY(-50%)',
            margin: '8px',
            zIndex: '1000',
          }}
        />
        <Button
          circular
          size="tiny"
          compact
          icon="angle right"
          onClick={this.nextSlide}
          style={{
            float: 'right',
            position: 'absolute',
            right: '0px',
            top: '50%',
            transform: 'translateY(-50%)',
            margin: '8px',
            zIndex: '1000',
          }}
        />
      </div>
    );
  }
}

export default Slide;
