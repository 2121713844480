// Description :
// -> Defines the component ListItem

// React requirement
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { DarkThemeContext, Photo } from '%/core';

import DataTitle from '../DataTitle';

import styles from './ListItem.module.css';

const Wrapper = (props) => {
  const [darktheme] = useContext(DarkThemeContext);
  return (
    <Link
      {...props}
      className={classNames(props.className, styles.wrapper, {
        [styles['wrapper--dark']]: darktheme === 'dark',
      })}
    />
  );
};

const ListItem = ({ src, alt, text, redirectTo, ...props }) => (
  <Wrapper to={redirectTo} {...props}>
    <Photo src={src} name={alt} circular width="40px" ratio={1} /> <DataTitle>{text}</DataTitle>
  </Wrapper>
);

export default ListItem;
