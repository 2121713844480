// Description :
// -> Component to fetch and show users for the rapidSearch

// React requirement
import React from 'react';

// Action
import apolloGetSearchUser from './apolloGetSearchUser.action';

// Styles
import ListItem from './ListItem';
import Title from './Title';

import { USER } from '../../categories';

const ResultsSearchUser = ({
  data: { loading, error, searchUser },
  updateActiveDisplayed,
  activeDisplayed,
  onClick,
}) => {
  if (loading) {
    return <p>Loading ...</p>;
  }
  if (error) {
    return <p>{error.message}</p>;
  }

  updateActiveDisplayed(searchUser.length);

  if (!searchUser.length) {
    return null;
  }

  const users = searchUser
    .filter((_, index) => index < activeDisplayed)
    .map((user) => (
      <ListItem
        role="menuitem"
        onClick={onClick}
        key={user.user.id}
        redirectTo={`/user/${user.user.login}`}
        src={
          user.user.personalPhotoURI ||
          user.user.ctiPhotoURI ||
          `${process.env.PUBLIC_URL}/images/placeholder.png`
        }
        alt={user.user.login}
        text={
          <div>
            {user.user.firstName} {user.user.lastName}{' '}
            <div style={{ color: 'grey', align: 'right' }}>{user.field}</div>
          </div>
        }
      />
    ));
  return [
    <Title key="title">{USER}s</Title>,
    <div key="users" role="menu">
      {users}
    </div>,
  ];
};

export default apolloGetSearchUser(ResultsSearchUser);
