/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
// Description :
// -> Header of the website : The navbar
// -> Show the routes
// -> Allows to navigate through the website

// React requirement
import gql from 'graphql-tag';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-apollo';

import { Icon, Image, Menu, Label } from 'semantic-ui-react';

// Local requirement
import Search from './Search';
import Bar from './Bar';
import { Photo, NavBarDropdown, DarkThemeToggler, DarkThemeContext } from '%/core';

import Navitem from './Navitem';

import theme from '../../../theme.js';
import { isAdmin } from '%/utils';

const DEFAULT_INTERVAL = 1000 * 60 * 5;
// eslint-disable-next-line import/no-mutable-exports
let refetchDesktop;

const defaultOptions = {
  as: Link,
  active: false,
};

const ThemeOptions = theme.links.map((option) => ({
  ...defaultOptions,
  ...option,
}));

const ThemeDropdown = () =>
  theme.DROPDOWN ? (
    <NavBarDropdown
      text={
        <Navitem>
          <Icon name="warning" size="big" />
        </Navitem>
      }
      icon={null}
      options={ThemeOptions}
      simple
    />
  ) : (
    <div />
  );

const AssociationMenuOptions = [
  {
    key: 'associations',
    icon: 'users',
    text: 'Liste des associations',
    to: '/associations',
    selected: false,
  },
  {
    key: 'new-association',
    icon: 'user plus',
    text: 'Créer une association',
    to: '/association/new',
  },
].map((option) => ({
  ...defaultOptions,
  ...option,
}));

const EventMenuOptions = [
  {
    key: 'calendar',
    icon: 'calendar',
    text: 'Calendrier',
    to: '/calendar',
    selected: false,
  },
  {
    key: 'allevents',
    icon: 'file alternate',
    text: 'Actualité',
    to: '/events',
  },
  {
    icon: 'calendar plus',
    key: 'createevents',
    text: 'Créer un évènement',
    to: '/event/new',
  },
  {
    icon: 'bolt',
    key: 'createnews',
    text: 'Créer une news',
    to: '/news/new',
  },
].map((option) => ({
  ...defaultOptions,
  ...option,
}));

const AdminMenuOptions = [
  {
    key: 'validation',
    icon: 'check circle',
    text: "Validation d'asso",
    to: '/associations/validate',
    selected: false,
  },
  {
    key: 'api',
    icon: 'exchange',
    text: 'API',
    to: '/api-admin',
    selected: false,
  },
  {
    key: 'api-preprod',
    icon: 'file code',
    text: 'API Preprod',
    to: '/api-preprod-admin',
    selected: false,
  },
  {
    key: 'odj',
    icon: 'list ul',
    text: 'Wiki et OdJ',
    to: '/odj-wiki',
    selected: false,
  },
].map((option) => ({
  ...defaultOptions,
  ...option,
}));

const AdminDropdown = () =>
  isAdmin() ? (
    <NavBarDropdown
      text={
        <Navitem>
          <Icon name="user secret" size="big" />
        </Navitem>
      }
      icon={null}
      options={AdminMenuOptions}
      simple
    />
  ) : (
    <div />
  );

const PromotionOptions = [
  {
    key: 'promotion',
    icon: 'graduation cap',
    text: 'Promotion',
    to: '/promotion',
    selected: false,
  },
  {
    key: 'random',
    icon: 'random',
    text: 'Personne au hasard',
    to: '/user/random',
  },
].map((option) => ({
  ...defaultOptions,
  ...option,
}));

const UpgradeMenuOptions = [
  {
    key: 'rfc',
    icon: 'angle double up',
    text: "Propositions d'amélioration",
    to: '/improvements',
    selected: false,
  },
  {
    key: 'bug',
    icon: 'bug',
    text: 'Signaler un bug',
    to: '/report/bug',
  },
].map((option) => ({
  ...defaultOptions,
  ...option,
}));

const InfosMenuOptions = [
  {
    key: 'guide',
    icon: 'info',
    text: "Guide d'utilisation",
    to: '/guide',
    selected: false,
  },
  {
    key: 'theme',
    icon: 'theme',
    text: 'Guide des thèmes',
    to: '/theme',
  },
  {
    key: 'utils',
    icon: 'linkify',
    text: 'Liens utiles',
    to: '/utils',
  },
  {
    key: 'versions',
    icon: 'file',
    text: 'Changelog',
    to: '/versions',
  },
  {
    key: 'api',
    icon: 'cogs',
    text: 'API',
    to: '/api',
  },
].map((option) => ({
  ...defaultOptions,
  ...option,
}));

const GET_UNREAD_NUMBER = gql`
  query getUnreadNumber {
    getUnreadNumber
  }
`;

const ME_FOR_PHOTO = gql`
  {
    me {
      personalPhotoURI
      ctiPhotoURI
    }
  }
`;

const NavBar = () => {
  const [darktheme] = useContext(DarkThemeContext);

  const { loading: unreadLoading, data: unreadData, refetch } = useQuery(GET_UNREAD_NUMBER, {
    pollInterval: DEFAULT_INTERVAL,
  });
  refetchDesktop = refetch;

  const { loading: photoLoading, data: photoData } = useQuery(ME_FOR_PHOTO);
  let photoURI = `${process.env.PUBLIC_URL}/images/placeholder.png`;
  if (!photoLoading) {
    photoURI =
      (photoData && photoData.me && photoData.me.personalPhotoURI) ||
      (photoData && photoData.me && photoData.me.ctiPhotoURI) ||
      `${process.env.PUBLIC_URL}/images/placeholder.png`;
  }

  return (
    <div style={{ height: '31px' }}>
      <Bar id="navbar">
        <Navitem style={{ cursor: 'pointer' }}>
          <Image
            src={`${process.env.PUBLIC_URL}/images/chain_transparent.svg`}
            size="mini"
            verticalAlign="middle"
            as={Link}
            to="/"
            style={{
              marginLeft: '-0.1em',
              marginRight: '-0.1em',
              marginTop: '-0.8em',
              marginBottom: '-0.7em',
            }}
          />
        </Navitem>
        <ThemeDropdown />
        <NavBarDropdown
          text={
            <Link to="/associations" style={{ color: 'white' }}>
              <Navitem>
                <Icon name="users" size="big" />
              </Navitem>
            </Link>
          }
          icon={null}
          options={AssociationMenuOptions}
        />
        <NavBarDropdown
          text={
            <Link to="/calendar" style={{ color: 'white' }}>
              <Navitem>
                <Icon name="calendar alternate" size="big" />
              </Navitem>
            </Link>
          }
          icon={null}
          options={EventMenuOptions}
        />
        <AdminDropdown />
        <Navitem style={{ flexGrow: 1 }}>
          <Search />
        </Navitem>
        <Navitem style={{ cursor: 'pointer', paddingTop: '0.6em' }}>
          <Link to="/notifications">
            <Menu secondary icon style={{ marginBottom: '-20%', marginRight: '0.3em' }}>
              <Menu.Item style={{ color: 'white', padding: '0', margin: '0' }}>
                <Icon name="bell" size="big" />
                {!unreadLoading && unreadData.getUnreadNumber > 0 && (
                  <Label
                    color="orange"
                    size="mini"
                    floating
                    style={{ transform: 'translateY(0.4em)' }}
                  >
                    {unreadData.getUnreadNumber}
                  </Label>
                )}
              </Menu.Item>
            </Menu>
          </Link>
        </Navitem>
        <NavBarDropdown
          text={
            <Navitem style={{ cursor: 'pointer' }}>
              <Link to="/promotion" style={{ color: 'white' }}>
                <Icon name="graduation cap" horizontalOffset={1} size="big" />
              </Link>
            </Navitem>
          }
          icon={null}
          options={PromotionOptions}
          direction="left"
        />
        <NavBarDropdown
          text={
            <Link to="/improvements" style={{ color: 'white' }}>
              <Navitem>
                <Icon name="angle double up" size="big" />
              </Navitem>
            </Link>
          }
          icon={null}
          options={UpgradeMenuOptions}
          direction="left"
        />
        <NavBarDropdown
          text={
            <Link to="/guide" style={{ color: 'white' }}>
              <Navitem>
                <Icon name="info" size="big" />
              </Navitem>
            </Link>
          }
          icon={null}
          options={InfosMenuOptions}
          direction="left"
        />

        <NavBarDropdown
          icon={null}
          direction="left"
          text={
            <Navitem>
              <Link
                to="/me"
                style={{
                  border: 'solid',
                  marginRight: '1em',
                  borderRadius: '120px',
                  borderWidth: '2.5px',
                  borderColor: 'White',
                }}
              >
                <Photo src={photoURI} width="1.86em" ratio={1} circular />
              </Link>
            </Navitem>
          }
        >
          <NavBarDropdown.Menu
            style={{ backgroundColor: darktheme === 'dark' ? '#505050' : 'white' }}
          >
            <NavBarDropdown.Item
              text="Se déconnecter"
              as={Link}
              to="/logout"
              icon="power"
              style={{ color: 'var(--font-color)' }}
            />
            <NavBarDropdown.Item style={{ color: 'var(--font-color)' }}>
              <DarkThemeToggler />
            </NavBarDropdown.Item>
          </NavBarDropdown.Menu>
        </NavBarDropdown>
      </Bar>
    </div>
  );
};

export default NavBar;
export { refetchDesktop };
