import React, { Component } from 'react';

import { Photo } from '%/core';
import { Modal, Button } from 'semantic-ui-react';

class ZoomPhoto extends Component {
  constructor(props) {
    super(props);

    this.state = { zoomPhoto: false };
  }

  handleZoomPhoto = () => {
    this.setState({ zoomPhoto: true });
  };

  handleDezoomPhoto = () => {
    this.setState({ zoomPhoto: false });
  };

  render() {
    const { src } = this.props;
    return (
      <React.Fragment>
        <Photo
          src={src}
          {...this.props}
          onClick={this.handleZoomPhoto}
          style={{ container: { cursor: 'pointer' } }}
        />
        <Modal
          open={this.state.zoomPhoto}
          style={{ textAlign: 'center' }}
          content={
            <div
              style={{
                position: 'relative',
                display: 'inline-block',
                maxWidth: '85%',
              }}
            >
              <Photo
                src={src}
                style={{
                  img: {
                    maxHeight: '80vh',
                    width: src.slice(-4) === '.svg' ? '80vh' : 'auto',
                    backgroundColor: 'white',
                  },
                }}
              />
              <Button
                icon="delete"
                size="tiny"
                onClick={this.handleDezoomPhoto}
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  right: '-1em',
                  top: '-1em',
                }}
                circular
                compact
                active
              />
            </div>
          }
          basic
        />
      </React.Fragment>
    );
  }
}

export default ZoomPhoto;
