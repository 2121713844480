import React from 'react';

import SanitizeDiv from '../sanitize/SanitizeDiv';
import './LoadingBar.css';

const FixedLoading = ({ style = {}, ...props }) => (
  <SanitizeDiv
    {...props}
    style={{
      color: 'lightgray',
      backgroundColor: 'lightgray',
      overflow: 'hidden',
      borderRadius: '1em',
      width: '100%',
      display: 'inline-block',
      ...style,
    }}
  />
);

const AnimatedLoading = (props) => (
  <SanitizeDiv
    {...props}
    style={{
      backgroundColor: 'gainsboro',
      borderRadius: '1em',
      animationDuration: '3s',
      animationName: 'loading',
      animationIterationCount: 'infinite',
      animationTimingFunction: 'linear',
      display: 'inline-block',
    }}
  />
);

const LoadingBar = (props) => (
  <span style={{ verticalAlign: 'text-top' }}>
    {/* <Icon name="clock" /> */}
    <FixedLoading {...props}>
      <AnimatedLoading> </AnimatedLoading>
    </FixedLoading>
  </span>
);

export default LoadingBar;
