import React from 'react';
import { Only } from '@blocz/react-responsive';

import Grid from '../grid/Grid';
import List from '../list/List';

const GridList = ({ ...props }) => {
  if (props.styled === 'grid') {
    return Grid(props);
  }
  if (props.styled === 'list') {
    return List(props);
  }
  return (
    <React.Fragment>
      <Only {...props} on="smDown" as={List} />
      <Only {...props} on="mdUp" size={props.size} as={Grid} />
    </React.Fragment>
  );
};

export default GridList;
