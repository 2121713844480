// Description :
// -> Defines the component ButtonEdit

// React requirement
import React from 'react';
import { withRouter } from 'react-router';
import { Icon } from 'semantic-ui-react';
import { Button } from '%/core';

const icon = <Icon name="pencil" style={{ margin: 0 }} />;

const ButtonEdit = (props) => {
  const onClick = () => props.onClick(props.news);
  return (
    <Button icon circular onClick={onClick}>
      {icon}
    </Button>
  );
};

export default withRouter(ButtonEdit);
