// Description :
// -> Defines the component SearchWrapper

// React requirement
import React from 'react';
// Local requiremnt
import ResultsSearchs from './ResultsSearchs';

const limitSearch = 3;

const SearchWrapper = ({ type, termSearch, updateActiveDisplayed, activeDisplayed, onClick }) => {
  const ResultsSearch = ResultsSearchs[type] || (() => {});
  if (termSearch === '') {
    return null;
  }
  const props = {
    termSearch,
    limit: limitSearch,
    updateActiveDisplayed,
    activeDisplayed,
    onClick,
  };
  return <ResultsSearch {...props} />;
};

export default SearchWrapper;
