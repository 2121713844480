import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Button, Icon } from 'semantic-ui-react';
import ReactMarkdown from 'react-markdown';

import { DarkThemeContext } from '../index.js';

class MarkdownText extends Component {
  constructor(props) {
    super(props);
    this.showMore = this.showMore.bind(this);
    this.contentHeight = 0;

    // Disable image display: removes ![...](...)
    let cleanContent = props.content.toString().replace(/!\[[\S\s]*\]\([\S\s]*\)/gm, '');
    // Reduce headers size
    if (props.reduceHeaders) {
      cleanContent = cleanContent
        .replace(/^##### /gm, '###### ')
        .replace(/^#### /gm, '##### ')
        .replace(/^### /gm, '#### ')
        .replace(/^## /gm, '### ')
        .replace(/^# /gm, '## ');
    }
    this.state = { show: false, contentOverflow: false, content: cleanContent };
  }

  componentDidMount() {
    // Check if text is too high
    if (!this.state.contentOverflow) {
      if (this.contentHeight === 0) {
        this.contentHeight = parseFloat(getComputedStyle(this.contentDiv).height);
      }
      this.setState((state) => ({
        show: state.show,
        contentOverflow:
          this.contentHeight + parseFloat(getComputedStyle(this.contentDiv).paddingTop) >
          this.props.maxHeight * parseFloat(getComputedStyle(this.contentDiv).fontSize),
      }));
    }
  }

  showMore() {
    this.setState((state) => ({
      show: !state.show,
      contentOverflow: state.contentOverflow,
    }));
  }

  render() {
    const [darktheme] = this.context;
    const markdownAndFade = (
      <React.Fragment>
        <div
          style={{
            overflow: 'hidden',
            maxHeight:
              this.state.show || this.props.noPlus
                ? 'none'
                : `calc(${this.props.maxHeight + 'em'} + 1px)`,
            height:
              this.state.show || !this.state.contentOverflow || this.props.noPlus ? 'auto' : '50%',
            zIndex: 1,
            maskImage: `${
              !this.state.show && this.state.contentOverflow && !this.props.noPlus
                ? 'linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))'
                : 'none'
            }`,
            ...this.props.style,
          }}
          ref={(node) => {
            this.contentDiv = node;
          }}
        >
          <ReactMarkdown source={this.state.content} />
        </div>
      </React.Fragment>
    );
    return (
      <div style={{ display: 'block' }}>
        {this.props.clickableText ? (
          <Link
            to={this.props.clickableText && this.props.buttonLink.url}
            style={{ color: 'rgba(0, 0, 0, .87)' }}
          >
            {markdownAndFade}
          </Link>
        ) : (
          markdownAndFade
        )}
        {this.state.contentOverflow && (
          <div
            style={{
              marginBottom: '2.5em',
              // marginTop: `-${(this.props.maxHeight * 2) / 3 - 4}px`,
            }}
          >
            {this.props.buttonLink && (
              <Link to={this.props.buttonLink.url}>
                <Button compact onClick={this.showMore} size="tiny" floated="right">
                  {this.props.buttonLink.title}
                </Button>
              </Link>
            )}

            {!this.props.buttonLink && !this.props.noPlus && (
              <Button
                basic={darktheme === 'dark'}
                inverted={darktheme === 'dark'}
                compact
                icon
                onClick={this.showMore}
                size="tiny"
                floated="right"
              >
                <Icon name={this.state.show ? 'triangle up' : 'triangle down'} />{' '}
                {this.state.show ? 'Moins' : 'Plus'}
              </Button>
            )}
          </div>
        )}
      </div>
    );
  }
}
MarkdownText.contextType = DarkThemeContext;

MarkdownText.defaultProps = {
  content: '',
  maxHeight: 10,
};

export default MarkdownText;
