import React from 'react';
import { Icon, Divider } from 'semantic-ui-react';

import { BlurSegment, LoadingBar, Header } from '%/core';

const NewsItemLoading = () => (
  <BlurSegment>
    <Header
      style={{
        marginBottom: '0',
        marginLeft: '0.3rem',
        marginTop: '0.5em',
      }}
      as="h3"
    >
      <Icon name="newspaper" />
      News
    </Header>
    <Divider />
    <LoadingBar width="20em" marginBottom="1em" />
    <LoadingBar height="4em" />
  </BlurSegment>
);

export default NewsItemLoading;
