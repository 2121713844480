// Description :
// -> Defines the component ActionDelete

// React requirement
import React, { Component } from 'react';
import { ApolloConsumer } from 'react-apollo';
import { withRouter } from 'react-router';

import { Confirm } from '%/core';

import { loader } from 'graphql.macro';

const deleteNews = loader('./deleteNews.gql');

class ActionDelete extends Component {
  state = { open: false };

  confirm = () => this.setState({ open: false });

  open = () => this.setState({ open: true });

  close = () => this.setState({ open: false });

  render() {
    const { newsId, children } = this.props;
    return (
      <React.Fragment>
        <ApolloConsumer>
          {(client) => (
            <Confirm
              open={this.state.open}
              title="Suppression de la news"
              message="Vous vous apprêtez à supprimer
    la news. Cette opération est irréversible."
              cancel={this.close}
              confirm={() => {
                this.confirm();
                client
                  .mutate({
                    mutation: deleteNews,
                    variables: { id: newsId },
                  })
                  .then(() =>
                    this.props.eventId
                      ? window.location.replace(`/event/${this.props.eventId}`)
                      : window.location.replace(
                          this.props.associationCode
                            ? `/association/${this.props.associationCode}`
                            : '/',
                        ),
                  );
              }}
            />
          )}
        </ApolloConsumer>
        {children(this.open)}
      </React.Fragment>
    );
  }
}

export default withRouter(ActionDelete);
