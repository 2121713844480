import './theme.css';

const CUSTOMTHEME =
  localStorage.getItem('isBlackout') === 'false' &&
  localStorage.getItem('isRondade') === 'false' &&
  localStorage.getItem('isMirror') === 'false' &&
  localStorage.getItem('isInvert') === 'false';
const DROPDOWN = false;
const CUSTOMNOTICE =
  localStorage.getItem('isBlackout') === 'false' &&
  localStorage.getItem('isRondade') === 'false' &&
  localStorage.getItem('isMirror') === 'false' &&
  localStorage.getItem('isInvert') === 'false';

const links = [
  {
    key: 'orga-link',
    icon: 'users',
    text: "L'organisateur : le BDE",
    to: '/association/bureau-des-eleves-de-centralesupelec-776',
    selected: false,
  },
  {
    key: 'fb-link',
    icon: 'facebook',
    text: "Le facebook de l'évènement",
    to: '/fb-bde',
    selected: false,
  },
  {
    key: 'insta-link',
    icon: 'instagram',
    text: "L'instagram de l'évènement",
    to: '/insta-bde',
    selected: false,
  },
  {
    key: 'site-link',
    icon: 'globe',
    text: "Le site de l'Eclipse",
    to: '/site-eclipse',
    selected: false,
  },
];

const linksMobile = [
  {
    category: 'Event',
    key: 'custom-theme-event',
    items: [
      {
        key: 'orga-link',
        icon: 'users',
        text: "L'organisateur : le BDE",
        to: '/association/bureau-des-eleves-de-centralesupelec-776',
        selected: false,
      },
      {
        key: 'fb-link',
        icon: 'facebook',
        text: "Le facebook de l'évènement",
        to: '/fb-bde',
        selected: false,
      },
      {
        key: 'insta-link',
        icon: 'instagram',
        text: "L'instagram de l'évènement",
        to: '/insta-bde',
        selected: false,
      },
      {
        key: 'site-link',
        icon: 'globe',
        text: "Le site de l'Eclipse",
        to: '/site-eclipse',
        selected: false,
      },
    ],
  },
];

if (CUSTOMTHEME) {
  document.body.classList.add('custom');
}

if (process.env.REACT_APP_COLOR && process.env.REACT_APP_COLOR !== 'default') {
  document.body.classList.add(process.env.REACT_APP_COLOR);
}

export default {
  CUSTOMTHEME,
  DROPDOWN,
  CUSTOMNOTICE,
  links,
  linksMobile,
};
