import React from 'react';

import { Only } from '@blocz/react-responsive';

const genOverlayTop = (rgbColor, alpha) => ({ style = {}, ...props }) => (
  <div
    {...props}
    style={{
      color: 'white',
      textAlign: 'left',
      fontWeight: 700,
      lineHeight: '1.4em',
      fontSize: '1.4em',
      backgroundColor: `rgba(${rgbColor}, ${alpha})`,
      width: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      padding: '5px',
      border: '0',
      zIndex: '1 !important',
      height: '100%',
      ...style,
    }}
  />
);

const TileContainer = ({ style = {}, url, ...props }) => (
  <div
    {...props}
    style={{
      border: 0,
      position: 'relative',
      backgroundImage: `url(${url})`,
      overflow: 'hidden',
      borderTopLeftRadius: '0.3em',
      borderTopRightRadius: '0.3em',
      borderBottomLeftRadius: '0.3em',
      borderBottomRightRadius: '0.3em',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      paddingBottom: `${(1 / 5) * 100}%`,
      ...style,
    }}
  />
);

// data.title : title of the title
// data.url : link it points to
// data.image : url of the tile background image
const TileMobile = ({ data, loading, color }) => {
  let rgbColor = [0, 0, 0];
  let alpha = 0.2;
  if (color !== undefined) {
    const decimalColor = parseInt(color.substr(1), 16);
    /* eslint-disable no-bitwise */
    rgbColor = [
      (decimalColor >> 16) & 0xff,
      (decimalColor >> 8) & 0xff,
      decimalColor & 0xff,
    ].join();
    alpha = 0.2;
  }
  const OverlayTop = genOverlayTop(rgbColor, alpha);
  if (loading) {
    return (
      <React.Fragment>
        <TileContainer url={`${process.env.PUBLIC_URL}/images/event_default.jpg`}>
          <OverlayTop active>
            <span>Chargement...</span>
          </OverlayTop>
        </TileContainer>
      </React.Fragment>
    );
  }

  return (
    <a href={data.url}>
      <TileContainer
        url={data.image ? data.image : `${process.env.PUBLIC_URL}/images/event_default.jpg`}
      >
        <OverlayTop active>
          {/* <span
            style={{
              position: 'absolute',
              fontSize: '0.7em',
              lineHeight: '0.7em',
              top: '0.4em',
              left: '5px',
            }}
          >
            // Top left
          </span> */}
          {/* <div
            style={{
              lineHeight: '0.6em',
              position: 'absolute',
              bottom: '0.5em',
              left: '5px',
            }}
          >
            // Bottom left
          </div> */}
          <span
            style={{
              position: 'absolute',
              fontSize: '1em',
              lineHeight: '1em',
              textTransform: 'uppercase',
              top: '0.4em',
              right: '5px',
            }}
          >
            <Only on="xsDown">
              {data.title.length > 28 ? `${data.title.substring(0, 28)}...` : data.title}
            </Only>
            <Only on="sm">
              {data.title.length > 38 ? `${data.title.substring(0, 38)}...` : data.title}
            </Only>
          </span>
          {/* <span
            style={{
              fontSize: '0.6em',
              lineHeight: '0.6em',
              position: 'absolute',
              bottom: '0.5em',
              right: '5px',
            }}
          >
            // Bottom right
          </span> */}
        </OverlayTop>
      </TileContainer>
    </a>
  );
};

export default TileMobile;
