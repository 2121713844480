// Description :
// -> Defines the routes of the website
// -> Like a map for navigation

// React requirement
import React, { Suspense, lazy } from 'react';
import { Route, Switch, Redirect } from 'react-router';

// Local requirement
import { ErrorBlock } from '%/core'; // To check if the user is already connected
import { hasToken, isConnected, getLogin, setUrl } from '%/utils';

// Redirection's url
import { loginUrl, refreshLoginUrl } from './config/route.config';

// View components
import Logout from './views/Logout';
import Main from './views/Main'; // Main view
import Login from './views/Login'; // Login view

const HomePage = lazy(() => import('./views/HomePage')); // Main view
const ProfilePage = lazy(() => import('./views/ProfilePage')); // Profile view
const RandomProfile = lazy(() => import('./views/RandomProfile')); // Profile view
const ProfileEditPage = lazy(() => import('./views/ProfileEditPage')); // Profile view
const SearchPage = lazy(() => import('./views/SearchPage')); // Search view
const AllAssociationsPage = lazy(() => import('./views/AllAssociationsPage')); // AllAssociations view
const AssociationValidationPage = lazy(() => import('./views/AssociationValidationPage')); // AssociationValidation view
const AssociationPage = lazy(() => import('./views/AssociationPage')); // Association view
const AssociationNewPage = lazy(() => import('./views/AssociationNewPage')); // Association view
const ErrorPage = lazy(() => import('./views/ErrorPage')); // Error view
const EventNewPage = lazy(() => import('./views/EventNewPage')); // Create Event view
const BugTrackerPage = lazy(() => import('./views/BugTrackerPage')); // BugTracker view
const UserGuidePage = lazy(() => import('./views/UserGuidePage')); // UserGuide view
const RFCPage = lazy(() => import('./views/RFCPage')); // RFCs view
const VersionsPage = lazy(() => import('./views/VersionsPage')); // Versions view
const UtilsPage = lazy(() => import('./views/UtilsPage')); // Utils view
const APIPage = lazy(() => import('./views/APIPage')); // Utils view
const EventPage = lazy(() => import('./views/EventPage')); // Event view
const NotificationsPage = lazy(() => import('./views/NotificationsPage')); // Notifications view
const NotificationsSettingsPage = lazy(() => import('./views/NotificationsSettingsPage')); // NotificationsSettings view
const NewsNewPage = lazy(() => import('./views/NewsNewPage')); // Create News view
const Calendar = lazy(() => import('./views/CalendarPage'));
const ThemeTutorial = lazy(() => import('./views/ThemeTutorialPage'));
const Croco = lazy(() => import('./views/CrocoPage'));

const EventsPage = lazy(() => import('./views/EventsPage')); // Events view
const PromotionPage = lazy(() => import('./views/PromotionPage')); // Promotion view

const MainRoute = (props) => (
  <Route
    render={(p) => {
      if (isConnected()) {
        // eslint-disable-next-line
        return <Main {...p} children={props.children} history={props.history} />;
      }
      // Login with token
      if (!hasToken()) {
        setUrl(window.location.pathname);
        window.location.replace(loginUrl);
        return <div />;
      }
      // Reconnect by refreshing token
      setUrl(window.location.pathname);
      window.location.replace(refreshLoginUrl);
      return <div />;
    }}
  />
);

// The routes of the website
const routes = () => {
  return (
    <Switch>
      <Route path="/token" component={Login} />
      {isConnected() && <Redirect exact from="/me" to={`/user/${getLogin()}`} />}
      {isConnected() && <Redirect exact from="/me/edit" to={`/user/${getLogin()}/edit`} />}
      {isConnected() && <Redirect exact from="/user/me" to={`/user/${getLogin()}`} />}
      {isConnected() && <Redirect exact from="/user/me/edit" to={`/user/${getLogin()}/edit`} />}
      <MainRoute>
        <Suspense fallback={null}>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/user/random" component={RandomProfile} />
            <Route exact path="/user/:login" component={ProfilePage} />
            <Route exact path="/user/:login/edit" component={ProfileEditPage} />
            <Route exact path="/association/new" component={AssociationNewPage} />
            <Route exact path="/association/:code" component={AssociationPage} />
            <Route
              exact
              path="/association/:code/edit"
              render={(props) => <AssociationPage edit {...props} />}
            />
            <Route exact path="/association/:code/:compositionId" component={AssociationPage} />
            <Route
              exact
              path="/association/:code/:compositionId/edit"
              render={(props) => <AssociationPage edit {...props} />}
            />
            <Route path="/search/" component={SearchPage} />
            <Route exact path="/associations" component={AllAssociationsPage} />
            <Route exact path="/associations/validate" component={AssociationValidationPage} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/error/:error?" component={ErrorPage} />
            <Route exact path="/report/bug" component={BugTrackerPage} />
            <Route
              exact
              path="/report/association/:name"
              render={(props) => <BugTrackerPage assoReport {...props} />}
            />
            <Route exact path="/guide" component={UserGuidePage} />
            <Route exact path="/improvements" component={RFCPage} />
            <Route exact path="/versions" component={VersionsPage} />
            <Route exact path="/utils" component={UtilsPage} />
            <Route exact path="/api" component={APIPage} />
            <Route exact path="/theme" component={ThemeTutorial} />
            <Route exact path="/event/new" component={EventNewPage} />
            <Route exact path="/event/:id" component={EventPage} />
            <Route exact path="/notifications" component={NotificationsPage} />
            <Route exact path="/notifications/settings" component={NotificationsSettingsPage} />
            <Route exact path="/event/:id/edit" render={(props) => <EventPage edit {...props} />} />
            <Route exact path="/events" component={EventsPage} />
            <Route exact path="/promotion" component={PromotionPage} />
            <Route exact path="/news/new" component={NewsNewPage} />
            <Route exact path="/croco" component={Croco} />
            <Route
              exact
              path="/news/:id/add"
              render={(props) => <EventPage addnews {...props} />}
            />
            <Route exact path="/calendar" component={Calendar} />
            <Route
              exact
              path="/insta-bde"
              component={() => {
                window.open('https://www.instagram.com/eclipsecentralesupelec/');
                window.history.back();
                return null;
              }}
            />
            <Route
              exact
              path="/fb-bde"
              component={() => {
                window.open('https://www.facebook.com/eclipse.centralesupelec');
                window.history.back();
                return null;
              }}
            />
            <Route
              exact
              path="/site-eclipse"
              component={() => {
                window.open('https://eclipse-cs.fr/');
                window.history.back();
                return null;
              }}
            />
            <Route
              exact
              path="/api-admin"
              component={() => {
                window.open('https://api.linkcs.fr/admin');
                window.history.back();
                return null;
              }}
            />
            <Route
              exact
              path="/api-preprod-admin"
              component={() => {
                window.open('https://api.preprod.linkcs.fr/admin');
                window.history.back();
                return null;
              }}
            />
            <Route
              exact
              path="/odj-wiki"
              component={() => {
                window.open('https://gitlab.viarezo.fr/LinkCS/Wiki/-/wikis/home');
                window.history.back();
                return null;
              }}
            />
            <Route render={() => <ErrorBlock message="Erreur : La page demandée n'existe pas" />} />
          </Switch>
        </Suspense>
      </MainRoute>
    </Switch>
  );
};

export default routes;
