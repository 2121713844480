// Description :
// -> Components used for profilePage

// React requirement
import React from 'react';

// Semantic UI requirements
import { Grid } from 'semantic-ui-react';

// General
import { LoadingBar, SubButton, SubIcon } from '%/core';

const SingleItem = ({ data, loading, name, width, ...props }) => (
  <Grid.Column width={width} textAlign="left" style={{ display: 'flex' }}>
    <SubButton>
      <SubIcon name={name || 'circle'} />
    </SubButton>
    {loading ? (
      <LoadingBar
        style={{
          marginBottom: '0.5em',
          paddingTop: 0,
          width: '80%',
        }}
        {...props}
      />
    ) : (
      data
    )}
  </Grid.Column>
);

export default SingleItem;
