import { useDynamicStyles } from './useDynamicStyles';

const Container = ({ style, ...props }) => {
  const dynamicStyles = useDynamicStyles({
    sm: { maxWidth: '540px' },
    md: { maxWidth: '720px' },
    lg: { maxWidth: '960px' },
    xl: { maxWidth: '1140px' },
  });

  return (
    <div
      {...props}
      style={{
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        ...dynamicStyles,
        ...style,
      }}
    />
  );
};

export default Container;
