import React, { useContext } from 'react';
import { List as SList } from 'semantic-ui-react';
import { DarkThemeContext } from '%/core';

const List = (props) => {
  const [darktheme] = useContext(DarkThemeContext);
  return (
    <SList divided inverted={darktheme === 'dark'} verticalAlign="middle">
      {props.children}
    </SList>
  );
};

export default List;
