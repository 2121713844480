import { Header } from 'semantic-ui-react';
import { DarkThemeContext } from '%/core';
import { useContext } from 'react';

const ColoredHeader = (props) => {
  const [darktheme] = useContext(DarkThemeContext);
  return (
    <Header inverted={darktheme === 'dark'} {...props}>
      {props.children}
    </Header>
  );
};

ColoredHeader.Content = Header.Content;

export default ColoredHeader;
