import React from 'react';
import { Button, Popup } from 'semantic-ui-react';

class ControlledPopup extends React.Component {
  state = { open: false };

  toggleOpen = () => this.setState((prevState) => ({ open: !prevState.open }));

  close = () => this.setState(() => ({ open: false }));

  render() {
    this.props.style['borderColor'] = 'var(--calendar-lines)';
    return (
      <Popup
        position={this.props.popupPosition}
        offset={this.props.popupOffset}
        onScroll={this.close}
        open={this.state.open}
        style={this.props.style}
        trigger={
          <Button
            content={this.props.content}
            style={this.props.buttonStyle}
            onClick={this.props.onOpen() && this.toggleOpen}
            icon={this.props.icon}
          />
        }
      >
        {this.props.popupContent}
      </Popup>
    );
  }
}

export default ControlledPopup;
