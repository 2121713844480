import React from 'react';
import { Button } from '%/core';

const SubButton = (props) => (
  <Button
    icon
    size="mini"
    circular
    basic
    style={{
      marginRight: '9px',
      marginTop: '-3px',
      cursor: 'default',
      height: 'min-content',
    }}
  >
    {props.children}
  </Button>
);

export default SubButton;
