import { Line } from '%/core';

const Bar = (props) => (
  <Line
    {...props}
    fluid
    style={{
      backgroundColor: 'var(--primary)',
      color: 'var(--footer-color)',
      padding: '0 0.5em',
      zIndex: '2000',
      boxShadow: '0px 1px 5px 0px hsla(0, 0%, 0%, 0.5)',
    }}
  />
);

export default Bar;
