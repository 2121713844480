// Local requirement
import Bar from './Bar';

const FooterMobile = () => {
  return (
    <Bar>
      <div
        style={{
          padding: '1em',
          fontSize: '0.7em',
        }}
      >
        <p style={{ lineHeight: '150%', textAlign: 'center' }}>
          Les informations de LinkCS sont destinées à être utilisées exclusivement par les
          associations ainsi que les étudiantes et étudiants des campus de Paris Saclay, Metz et
          Rennes.
        </p>
      </div>
    </Bar>
  );
};

export default FooterMobile;
