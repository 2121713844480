// Description :
// -> Defines the component RapidSearch

// React requirement
import React, { Component } from 'react';

import { SanitizeDiv } from '%/core';

// Local requirement
import SearchWrapper from './SearchWrapper';

// Categories
import { USER, ASSO } from './categories';

import styles from './RapidSearch.module.css';

const maxDisplayed = 5;

class RapidSearch extends Component {
  state = { [USER]: 0, [ASSO]: 0 };

  updateActiveDisplayed = (type) => (number) => {
    if (this.state[type] !== number) {
      this.setState({ [type]: number });
    }
  };

  render() {
    return (
      <SanitizeDiv className={styles['rapid-search']}>
        <SearchWrapper
          type={USER}
          termSearch={this.props.termSearch}
          updateActiveDisplayed={this.updateActiveDisplayed(USER)}
          activeDisplayed={maxDisplayed}
          onClick={this.props.onClick}
        />
        <SearchWrapper
          type={ASSO}
          termSearch={this.props.termSearch}
          updateActiveDisplayed={this.updateActiveDisplayed(ASSO)}
          activeDisplayed={maxDisplayed - this.state[USER]}
          onClick={this.props.onClick}
        />
      </SanitizeDiv>
    );
  }
}

export default RapidSearch;
