import React from 'react';
import { Modal, Button } from 'semantic-ui-react';

const Confirm = ({ title, message, cancel, confirm, extraAction = null, open = true }) => {
  const baseActions = [
    <Button key="cancel" onClick={cancel}>
      Annuler
    </Button>,
    <Button key="confirm" color="teal" onClick={confirm}>
      Confirmer
    </Button>,
  ];

  const actions =
    extraAction !== null
      ? [
          ...baseActions,
          <Button key={extraAction.action} color="teal" onClick={extraAction.onClick}>
            {extraAction.text}
          </Button>,
        ]
      : baseActions;

  return <Modal open={open} header={title} content={message} actions={actions} />;
};

export default Confirm;
