// Description :
// -> Component to fetch and show assos for the rapidSearch

// React requirement
import React from 'react';

// Action
import apolloGetSearchAsso from './apolloGetSearchAsso.action';

// Styles
import ListItem from './ListItem';
import Title from './Title';

import { ASSO } from '../../categories';

const ResultsSearchAsso = ({
  data: { loading, error, searchAsso },
  updateActiveDisplayed,
  activeDisplayed,
  onClick,
}) => {
  if (loading) {
    return <p>Loading ...</p>;
  }
  if (error) {
    return <p>{error.message}</p>;
  }

  updateActiveDisplayed(searchAsso.length);

  if (!searchAsso.length) {
    return null;
  }

  const searchAssoSorted = searchAsso
    .filter((asso) => asso.type === 'ASSOCIATION')
    .concat(searchAsso.filter((asso) => asso.type === 'CLUB'))
    .concat(searchAsso.filter((asso) => asso.type === 'GROUPE'));
  const assos = searchAssoSorted
    .filter((_, index) => index < activeDisplayed)
    .map((asso) => (
      <ListItem
        role="menuitem"
        key={asso.id}
        onClick={onClick}
        redirectTo={`/association/${asso.code}`}
        src={asso.photoURI || `${process.env.PUBLIC_URL}/images/placeholder.png`}
        alt={asso.code}
        text={asso.name}
      />
    ));
  return [
    <Title key="title">{ASSO}s</Title>,
    <div key="associations" role="menu">
      {assos}
    </div>,
  ];
};

export default apolloGetSearchAsso(ResultsSearchAsso);
