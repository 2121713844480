import { Grid } from 'semantic-ui-react';

const CompactColumn = ({ style = {}, ...props }) => (
  <Grid.Column
    {...props}
    style={{
      padding: '0 !important',
      maxWidth: '7.5rem !important',
      ...style,
    }}
  />
);

export default CompactColumn;
