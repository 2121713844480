import React, { useContext } from 'react';
import { Card } from 'semantic-ui-react';

import { Photo, LoadingBar, DarkThemeContext } from '%/core';

const StyledCard = (props) => {
  const [darktheme] = useContext(DarkThemeContext);
  return (
    <Card
      {...props}
      style={{
        margin: '1em !important',
        zIndex: '1 !important',
        boxShadow: darktheme === 'dark' ? 'none' : '',
        borderRadius: '0.4em',
      }}
    />
  );
};

const GridItemPhoto = ({ to, src, header, content, loading, targetBlank }) => {
  const [darktheme] = useContext(DarkThemeContext);
  const cardBg = darktheme === 'dark' ? 'black' : 'white';
  return (
    <StyledCard href={loading ? '/' : to} target={!targetBlank ? '' : '_blank'}>
      <Photo
        src={loading ? `${process.env.PUBLIC_URL}/images/placeholder.png` : src}
        fluid
        ratio={1}
        rounded
        card
      />
      <Card.Content textAlign="center" style={{ background: cardBg }}>
        <Card.Header
          style={{
            overflowWrap: 'break-word',
            wordWrap: 'break-word',
            WebkitHyphen: 'auto',
            MsHyphens: 'auto',
            MozHyphens: 'auto',
            hyphens: 'auto',
            color: 'var(--font-color)',
          }}
        >
          {loading ? (
            <LoadingBar style={{ width: '75%', lineHeight: 1.25, margin: 'auto' }} />
          ) : (
            header
          )}
        </Card.Header>
        <Card.Description
          style={{
            whiteSpace: 'pre-line',
            overflowWrap: 'break-word',
            wordWrap: 'break-word',
            WebkitHyphen: 'auto',
            MsHyphens: 'auto',
            MozHyphens: 'auto',
            hyphens: 'auto',
            color: 'var(--font-color)',
          }}
        >
          {loading ? (
            <LoadingBar style={{ width: '85%', lineHeight: 1.25, margin: 'auto' }} />
          ) : (
            content
          )}
        </Card.Description>
      </Card.Content>
    </StyledCard>
  );
};

export default GridItemPhoto;
