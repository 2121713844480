// Local requirement
import { Button, Icon } from 'semantic-ui-react';
import Bar from './Bar';

const Footer = () => {
  return (
    <Bar>
      <footer
        style={{
          fontSize: '0.7em',
          margin: 'auto',
          fontWeight: 'bold',
          textAlign: 'center',
        }}
      >
        <p
          style={{
            lineHeight: 'var(--footer-height)',
          }}
        >
          Les informations de LinkCS sont destinées à être utilisées exclusivement par les
          associations ainsi que les étudiantes et étudiants des campus de Paris Saclay, Metz et
          Rennes.
        </p>
      </footer>
      <Button
        size="small"
        color="red"
        onClick={() => {
          var element = document.getElementById('blackout');
          element.classList.toggle('blackoutWrapper');
          localStorage['isBlackout'] = localStorage['isBlackout'] === 'false' ? 'true' : 'false';
        }}
      >
        <Icon name="remove" />
        Rallumez la lumière !
      </Button>
    </Bar>
  );
};

export default Footer;
