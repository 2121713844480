// Description :
// -> Defines the component Login

// React requirement
import React from 'react';
import { Redirect } from 'react-router';
import { withRouter } from 'react-router-dom';

import { setToken, checkToken, getUrl, clearUrl } from '%/utils';

const Login = (props) => {
  let token = props.location.search;
  token = token.replace(/.*[&?]token=/, '');
  token = token.replace(/&.*/, '');
  if (checkToken(token)) {
    setToken(token);
    if (window.matchMedia('(display-mode: standalone)').matches) {
      window.open('', '_self').close();
    }
    const url = getUrl();
    clearUrl();
    return <Redirect to={url} />;
  }
  return <div>Erreur</div>;
};

export default withRouter(Login);
