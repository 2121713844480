// Description :
// -> Defines the component ErrorBlock

// React requirement
import React from 'react';
import { Link } from 'react-router-dom';

const ErrorMessage = ({ style = {}, ...props }) => (
  <div
    {...props}
    style={{
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '15em',
      textAlign: 'center',
      maxWidth: '80%',
      color: 'var(--font-color)',
      ...style,
    }}
  />
);

const ErrorBlock = ({ message }) => (
  <ErrorMessage>
    <h1>Erreur : Une erreur est survenue</h1>
    {message ? <pre style={{ whiteSpace: 'pre-line' }}>{message}</pre> : null}
    <p>Si le problème persiste, veuillez contacter un administrateur.</p>
    <Link to="/">
      Revenir à l{"'"}
      accueil
    </Link>
  </ErrorMessage>
);

export default ErrorBlock;
