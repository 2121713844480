// This file contains all harcoded data used by the app

// President roleType id to know whether a user is president of their association
const PRESIDENT_ROLETYPE_ID = 1;

// "Archives" category id to know whether an association is former or not
const ARCHIVES_CATEGORY_ID = 24;

// Default empty composition data to load when a composition or an association is created
const EMPTY_COMPOSITION = {
  label: '',
  sectors: [
    {
      name: 'Bureau',
      isBureau: true,
      description: '',
      sectorOfMembers: true,
      priority: 100,
      roles: [
        {
          label: 'Prez',
          userId: 0,
          roleTypeId: 1,
          errors: [],
          priority: 1,
        },
        {
          label: 'Trez',
          userId: 0,
          roleTypeId: 2,
          errors: [],
          priority: 2,
        },
      ],
      errors: [],
    },
  ],
  errors: [],
};

const NEW_ASSOCIATION = {
  name: '',
  description: '',
  website: '',
  origin: 'CENTRALESUPELEC',
  compositions: [{ ...EMPTY_COMPOSITION }],
  categories: [],
  errors: [],
};

const NEW_EVENT = {
  title: '',
  location: '',
  description: '',
  beginningDate: '',
  endDate: '',
  tags: [],
  errors: [],
};
const NEW_NEWS = {
  title: '',
  content: '',
  tags: [],
  errors: [],
  event: -1,
};

export {
  PRESIDENT_ROLETYPE_ID,
  ARCHIVES_CATEGORY_ID,
  EMPTY_COMPOSITION,
  NEW_ASSOCIATION,
  NEW_EVENT,
  NEW_NEWS,
};
