import { Segment } from 'semantic-ui-react';
import { DarkThemeContext } from '%/core';
import { useContext } from 'react';

const BlurSegment = ({ ...props }) => {
  const [darktheme] = useContext(DarkThemeContext);
  return (
    <Segment
      {...props}
      style={{ ...props.style, borderRadius: '0.7em', background: 'var(--segment-opacity)' }}
      inverted={darktheme === 'dark'}
    />
  );
};

export default BlurSegment;
