// -> Defines the component ButtonSeeMore utilisé dans la home page pour les actualités et news

import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '%/core';

const ButtonSeeMore = ({ seeMoreLink }) => {
  return (
    <Link to={seeMoreLink}>
      <Button compact floated="right">
        Tout voir
      </Button>
    </Link>
  );
};

export default ButtonSeeMore;
