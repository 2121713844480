import React from 'react';

import { SanitizeDiv, SanitizeImg, Cropper } from '%/core';

/* Props
  src: String,
  name: String,
  centered: Bool (default false),
  circular: Bool (default false),
  rounded: Bool (default false),
  roundedTop: Bool (default false),
  shadow: Bool (default false),
  width: String (default null),
  height: String (default null),
  fluid: Bool (default false),
  ratio: Number (default none),
  maxWidth: String (default null)
  cropper; Bool (defaut false),

  style:
    container:
    img:

*/

const radius = '0.3em';

const is = (condition, value) => (condition && value) || '';

const styled = (Component, getStyles) => {
  const NewComponent = (props) => {
    const style = typeof getStyles === 'function' ? getStyles(props) : getStyles;
    const sanitizedStyle = Object.fromEntries(
      Object.entries(style).filter(([key, value]) => !!value),
    );
    return <Component {...props} style={{ ...props.style, ...sanitizedStyle }} />;
  };
  NewComponent.displayName = `styled(${Component.displayName || Component.name})`;
  return NewComponent;
};

const PhotoStyle = styled(
  SanitizeImg,
  ({
    centered,
    card,
    circular,
    rounded,
    roundedTop,
    shadow,
    width,
    height,
    style: userStyle = {},
  }) => {
    const style = { width: '100%', maxWidth: '100%', height: 'auto' };

    // Set Width and Height
    if (width) {
      style.width = width;
      style.height = 'auto';
    }
    if (height) {
      style.height = height;
      if (!width) {
        style.width = 'auto';
      }
    }

    if (centered) {
      style.margin = 'auto';
    }

    // Border radius
    if (circular) {
      style.borderRadius = '50%';
    } else if (rounded) {
      style.borderRadius = radius;
    } else if (roundedTop) {
      style.borderTopLeftRadius = radius;
      style.borderTopRightRadius = radius;
    } else if (card) {
      style.borderRadius = '.28571429rem .28571429rem 0 0!important';
    }

    if (shadow) {
      style.boxShadow = '0 1px 3px #888888';
    }

    return Object.assign(style, userStyle);
  },
);

// Component
const RatioPhotoStyle = styled(SanitizeImg, {
  display: 'none',
});

// Component
const RatioContainer = styled(
  SanitizeDiv,
  ({ ratio, card, circular, rounded, roundedTop, shadow, src }) => {
    let borderRadius = is(rounded, radius);
    const borderTopRightRadius = is(roundedTop, radius);
    const borderTopLeftRadius = is(roundedTop, radius);
    if (circular) {
      borderRadius = '50%';
    } else if (card) {
      borderRadius = '.3rem .3rem 0 0!important';
    }
    return {
      overflow: 'hidden',
      paddingTop: `${100 / ratio}%`,
      width: '100%',
      position: 'relative',
      boxShadow: is(shadow, '0 1px 3px #888888'),
      borderRadius,
      borderTopRightRadius,
      borderTopLeftRadius,
      backgroundImage: `url(${src})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    };
  },
);

// Component
const Container = styled(SanitizeDiv, ({ centered, width, maxWidth, fluid, container = {} }) =>
  Object.assign(
    {
      margin: is(centered, 'auto'),
      width: width || is(fluid, '100%'),
      maxWidth: maxWidth || '',
    },
    container,
  ),
);

const Photo = ({
  innerRef,
  src,
  name,
  centered,
  card,
  circular,
  rounded,
  roundedTop,
  shadow,
  width,
  fluid,
  ratio,
  maxWidth,
  cropper,
  style: { img, container },
  onClick,
}) => {
  const imgProps = {
    src,
    name,
  };
  const containerProps = {
    src,
    centered,
    width,
    maxWidth,
    fluid,
    circular,
    card,
    rounded,
    roundedTop,
    shadow,
    onClick,
  };
  if (cropper) {
    return <Cropper {...imgProps} />;
  }
  const PhotoComponent = () =>
    ratio ? (
      <RatioContainer {...containerProps} ratio={ratio} style={img} styleRef={innerRef}>
        <RatioPhotoStyle {...imgProps} />
      </RatioContainer>
    ) : (
      <PhotoStyle {...imgProps} style={img} styleRef={innerRef} />
    );
  return (
    <Container {...containerProps} ratio={ratio} style={container} styleRef={innerRef}>
      <PhotoComponent />
    </Container>
  );
};

Container.defaultProps = {
  centered: false,
};

PhotoStyle.defaultProps = {
  src: `${process.env.PUBLIC_URL}/images/placeholder.png`,
  name: 'Image',
  centered: true,
  circular: false,
  rounded: false,
  shadow: false,
  cropper: false,
};

Photo.defaultProps = {
  style: {
    img: {},
    container: {},
  },
};

export default Photo;
