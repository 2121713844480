// This file exports a function that validate the creation of an news
//   -> if the news is not valid, fill it with its errors and returns it
//   -> else if the news is valid, it can be sent to the back, and returns null

// React requirement
export default (news, newNews, isAuthorizedUser) => {
  let validate = true;
  const correction = { ...news };
  const { title, content, association } = correction;
  correction.errors = [];
  if (title === '') {
    correction.errors.push({
      name: 'title',
      message: 'Le titre de la news doit être renseigné',
    });
  }
  if (newNews && !Number.isInteger(association)) {
    correction.errors.push({
      name: 'association',
      message: 'Vous devez sélectionner une association',
    });
  }
  if (newNews && !isAuthorizedUser) {
    correction.errors.push({
      name: 'association',
      message: 'Vous ne possédez pas le droit de publier au nom de cette association',
    });
  }
  if (content === '') {
    correction.errors.push({
      name: 'content',
      message: 'Vous devez décrire la news',
    });
  }
  validate = validate && correction.errors.length === 0;
  return validate ? null : { ...correction, validate };
};
