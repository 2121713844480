/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
// Description :
// -> Header of the website : The navbar
// -> Show the routes
// -> Allows to navigate through the website

// React requirement
import gql from 'graphql-tag';
import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-apollo';

import { Icon, Menu, Sidebar, Label } from 'semantic-ui-react';
import { Header, DarkThemeContext, DarkThemeToggler } from '%/core';
import { isAdmin } from '%/utils';

// Local requirenment
import Search from './Search';
import Bar from './Bar';

import Navitem from './Navitem';

import themeData from '../../../theme.js';

const DEFAULT_INTERVAL = 1000 * 60 * 5;
// eslint-disable-next-line import/no-mutable-exports
let refetchMobile;

const NavBarHeight = '51px';

const PhoneMenuOptions = [
  {
    key: 'home',
    icon: 'home',
    text: 'Accueil',
    to: '/',
  },
  {
    key: 'me',
    icon: 'user',
    text: 'Mon profil',
    to: '/me',
  },
  {
    key: 'promotion',
    icon: 'graduation cap',
    text: 'Promotions',
    to: '/promotion',
  },
]
  .concat(themeData.DROPDOWN ? themeData.linksMobile : [])
  .concat([
    {
      category: 'Associations',
      key: 'category-associations',
      items: [
        {
          key: 'associations',
          icon: 'users',
          text: 'Liste des associations',
          to: '/associations',
        },
        {
          key: 'new-association',
          icon: 'user plus',
          text: 'Créer une association',
          to: '/association/new',
        },
      ],
    },
    {
      category: 'Évènements',
      key: 'category-events',
      items: [
        {
          key: 'calendar',
          icon: 'calendar alternate',
          text: 'Calendrier',
          to: '/calendar',
        },
        {
          key: 'allevents',
          icon: 'calendar',
          text: 'Actualité',
          to: '/events',
        },
        {
          icon: 'calendar plus',
          key: 'createevents',
          text: 'Créer un évènement',
          to: '/event/new',
        },
        {
          icon: 'bolt',
          key: 'createnews',
          text: 'Créer une news',
          to: '/news/new',
        },
      ],
    },
    {
      category: 'Autres',
      key: 'category-others',
      items: [
        {
          key: 'utils',
          icon: 'linkify',
          text: 'Liens utiles',
          to: '/utils',
        },
        {
          key: 'guide-user',
          icon: 'info',
          text: "Guide d'utilisation",
          to: '/guide',
        },
        {
          key: 'guide-theme',
          icon: 'theme',
          text: 'Guide des themes',
          to: '/theme',
        },
        {
          key: 'rfc',
          icon: 'angle double up',
          text: "Propositions d'amélioration",
          to: '/improvements',
        },
        {
          key: 'bug',
          icon: 'bug',
          text: 'Signaler un bug',
          to: '/report/bug',
        },
        {
          key: 'versions',
          icon: 'file',
          text: 'Changelog',
          to: '/versions',
        },
      ],
    },
    {
      key: 'disconnect',
      icon: 'power',
      text: 'Se déconnecter',
      to: '/logout',
    },
  ])
  .concat(
    isAdmin()
      ? [
          {
            category: 'Admin',
            key: 'category-admin',
            items: [
              {
                key: 'validation',
                icon: 'check circle',
                text: "Validation d'asso",
                to: '/associations/validate',
              },
              {
                key: 'api',
                icon: 'exchange',
                text: 'API',
                to: '/api-admin',
              },
              {
                key: 'api-preprod',
                icon: 'file code',
                text: 'API Preprod',
                to: '/api-preprod-admin',
              },
              {
                key: 'odj',
                icon: 'list ul',
                text: 'Wiki et OdJ',
                to: '/odj-wiki',
              },
            ],
          },
        ]
      : [],
  );

const GET_UNREAD_NUMBER = gql`
  query getUnreadNumber {
    getUnreadNumber
  }
`;

const NavBarMobile = (props) => {
  const [darktheme] = useContext(DarkThemeContext);
  const [visible, setVisible] = useState(false);
  const { loading, data, refetch } = useQuery(GET_UNREAD_NUMBER, {
    pollInterval: DEFAULT_INTERVAL,
  });
  refetchMobile = refetch;

  const toggleMobileMenu = () => {
    setVisible((prevVisible) => !prevVisible);
  };

  const hideMobileMenu = () => {
    setVisible(false);
  };

  return (
    <React.Fragment>
      <div style={{ height: NavBarHeight }}>
        <Bar id="navbar">
          <Navitem onClick={toggleMobileMenu}>
            <Icon name="bars" size="big" />
          </Navitem>
          <Navitem style={{ flexGrow: 1 }}>
            <Search />
          </Navitem>
          <Navitem style={{ marginRight: '2%' }}>
            <Link to="/notifications">
              <Menu secondary icon style={{ marginBottom: '-20%' }}>
                <Menu.Item as="a" style={{ color: 'white', padding: '0' }}>
                  <Icon name="bell" size="big" />
                  {!loading && data.getUnreadNumber > 0 && (
                    <Label color="orange" size="tiny" floating>
                      {data.getUnreadNumber}
                    </Label>
                  )}
                </Menu.Item>
              </Menu>
            </Link>
          </Navitem>
        </Bar>
      </div>
      <Sidebar.Pushable>
        <Sidebar
          inverted={darktheme === 'dark'}
          as={Menu}
          animation="overlay"
          visible={visible}
          vertical
          className="sidebarmenu"
        >
          <style>
            {`
              .sidebar.sidebarmenu {
                overflow-y: scroll !important;
                height: calc(100vh - ${NavBarHeight} + 1px) !important;
                position: fixed !important;
                top: ${window.pageYOffset - 1}px !important;
              }
            `}
          </style>
          {PhoneMenuOptions.map((item) =>
            item.category ? (
              <Menu.Item key={item.key} active={false}>
                <React.Fragment>
                  <Header size="tiny" style={{ marginBottom: '0' }}>
                    {item.category}
                  </Header>
                  <Menu.Menu>
                    {item.items.map((item_) => (
                      <Link to={item_.to} key={item_.key} onClick={hideMobileMenu}>
                        <Menu.Item active={false} style={{ color: 'var(--font-color)' }}>
                          <Icon name={item_.icon} style={{ float: 'left', marginRight: '0.8em' }} />
                          {item_.text}
                        </Menu.Item>
                      </Link>
                    ))}
                  </Menu.Menu>
                </React.Fragment>
              </Menu.Item>
            ) : (
              <Menu.Item key={item.key} active={false}>
                <Link
                  to={item.to.startsWith('http') ? '#' : item.to}
                  onClick={() => {
                    if (!item.category) {
                      hideMobileMenu();
                    }
                    if (item.to.startsWith('http')) {
                      window.location = item.to;
                    }
                  }}
                  style={{
                    color: 'var(--font-color)',
                    width: '100%',
                    display: 'inline-block',
                  }}
                >
                  <Icon name={item.icon} style={{ float: 'left', marginRight: '0.8em' }} />
                  {item.text}
                </Link>
              </Menu.Item>
            ),
          )}
          <Menu.Item>
            <DarkThemeToggler />
          </Menu.Item>
        </Sidebar>
        <Sidebar.Pusher
          dimmed={visible}
          style={{ minHeight: 'fit-content' }}
          onClick={hideMobileMenu}
        >
          {visible && (
            <style>
              {`
                body {
                  max-height: 100vh;
                  overflow-y: hidden;
                }
              `}
            </style>
          )}
          {props.children}
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </React.Fragment>
  );
};

export default NavBarMobile;
export { refetchMobile };
