// Description:
// -> The schema of back architecture
// -> Used by Apollo

// Requirements
import { makeExecutableSchema } from 'graphql-tools';
import { loader } from 'graphql.macro';

const rawSchema = loader('./schema.graphql');

const schema = makeExecutableSchema({
  typeDefs: rawSchema.loc.source.body,
});

export default schema;
