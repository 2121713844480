// Description :
// -> Component to search a user/asso/groupe

// React requirement
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Only } from '@blocz/react-responsive';

// Lodash
import debounce from 'lodash/debounce';
import { Icon } from 'semantic-ui-react';
import sluggr from 'sluggr';

// Local Requirement
import Input from './Input';
import RapidSearch from './RapidSearch';

import { activeElementNotInput } from '%/utils';

const slugger = sluggr(' ', "'");

class SearchBar extends Component {
  constructor(props) {
    super(props);

    const termSearch = this.props.location.pathname.match(/^\/search\//)
      ? slugger(this.props.location.pathname.replace(/^\/search\//, ''))
      : '';

    this.state = {
      showSearch: false,
      termSearch,
      lazyTermSearch: termSearch,
    };
  }

  componentDidMount() {
    window.addEventListener('keypress', this.handleKeyPress);
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keypress', this.handleKeyPress);
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  onChange = (event) => {
    this.setState({ termSearch: event.target.value });
    this.handleLazyChange(slugger(event.target.value));
  };

  handleLazyChange = (lazyTermSearch) => {
    if (lazyTermSearch) {
      this.toggleRapidSearch(true, { lazyTermSearch });
    } else {
      this.toggleRapidSearch(false, { lazyTermSearch });
    }
  };

  handleLazyChange = debounce(this.handleLazyChange, 350);

  handleSubmit = (event) => {
    event.preventDefault();
    const searchTerm = this.state.termSearch;
    if (searchTerm) {
      this.toggleRapidSearch(false);
      this.props.history.push(`/search/${searchTerm}`);
    }
  };

  handleKeyPress = (event) => {
    if (activeElementNotInput() && (event.key === 's' || event.key === 'S')) {
      this.searchRef.current.focus();
      event.preventDefault();
    }
  };

  handleKeyDown = (event) => {
    if (
      (activeElementNotInput() || document.activeElement === this.searchRef.current) &&
      event.keyCode === 27
    ) {
      this.searchRef.current.blur();
      document.body.focus();
      this.toggleRapidSearch(false);
    }
  };

  searchRef = React.createRef();

  toggleRapidSearch = (show, state = {}) => {
    if (this.props.location.pathname.match(/^\/search\//)) {
      this.setState({ showSearch: false, ...state });
      const term = state.lazyTermSearch || this.state.lazyTermSearch;
      this.props.history.push(`/search/${term}`);
      return;
    }
    this.setState({ showSearch: show, ...state });
  };

  closeSearch = () => {
    this.setState({ termSearch: '' });
    this.handleLazyChange('');
    this.toggleRapidSearch(false);
  };

  render() {
    const searchInput = (placeholder) => (
      <Input
        onChange={this.onChange}
        placeholder={placeholder}
        value={this.state.termSearch}
        innerRef={this.searchRef}
        autoComplete="off"
        id="mainSearch"
        onFocus={() => this.toggleRapidSearch(true)}
      />
    );

    return (
      <form
        onSubmit={this.handleSubmit}
        style={{
          marginBottom: '.3em',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Icon name="search" style={{ marginBottom: '.3em' }} />
        <Only on="mdUp">{searchInput("Cliquez ou appuyez sur 's' pour rechercher...")}</Only>
        <Only on="smDown">{searchInput('Cliquez pour rechercher...')}</Only>
        {this.state.termSearch.length > 0 && (
          <Icon
            name="close"
            style={{ color: 'white', fontSize: '1.3em' }}
            onClick={this.closeSearch}
          />
        )}
        {this.state.showSearch && (
          <RapidSearch termSearch={this.state.lazyTermSearch} onClick={this.closeSearch} />
        )}
      </form>
    );
  }
}

export default withRouter(SearchBar);
