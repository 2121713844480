import React from 'react';
import { Image, Grid, Divider, Icon } from 'semantic-ui-react';
import { BlurSegment, MarkdownText, SingleItem, Row, NewsItem, LikeAndGoing, Header } from '%/core';
import { Link } from 'react-router-dom';

const Event = ({
  id,
  title,
  association,
  assoCode,
  location,
  beginningDate,
  endDate,
  content,
  pictureSrc,
  newss,
  tags,
}) => (
  <BlurSegment
    style={{
      display: 'flex',
      flexDirection: 'column',
      // alignItems: 'center',
      maxWidth: '60em',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingLeft: '1.3em',
      paddingRight: '1.3em',
    }}
  >
    <h2
      style={{
        fontWeight: '1000',
        fontSize: '1.7rem',
        textAlign: 'center',
        marginBottom: '0em',
        marginTop: '0em',
      }}
    >
      <Link to={`/event/${id}`}>{title}</Link>
    </h2>

    <div style={{ marginTop: '1em', marginBottom: '1em' }}>
      <Grid>
        <Grid.Column>
          <Row>
            <SingleItem name="map marker alternate" data={location} width={4} />
          </Row>
          <Row>
            <SingleItem
              name="calendar alternate outline"
              data={endDate ? `${beginningDate} - ${endDate}` : beginningDate}
              width={4}
            />
          </Row>
          {association && assoCode && (
            <Row>
              <SingleItem
                name="users"
                data={
                  <React.Fragment>
                    <div>Organisé par&nbsp;</div>
                    <Link to={`/association/${assoCode}`}>{association}</Link>
                  </React.Fragment>
                }
                width={4}
              />
            </Row>
          )}
          {!tags.empty && (
            <Row>
              <SingleItem
                name="hashtag"
                data={
                  tags.type.length && tags.theme.length
                    ? `${tags.type.join(', ')} | ${tags.theme.join(', ')}`
                    : `${tags.type.join(', ')}${tags.theme.join(', ')}`
                }
              />
            </Row>
          )}
        </Grid.Column>
      </Grid>
    </div>

    {pictureSrc && (
      <Link to={`/event/${id}`}>
        <Image
          src={pictureSrc}
          style={{
            maxWidth: '100%',
            maxHeight: '20em',
          }}
          centered
          rounded
        />
      </Link>
    )}

    {content && content.length !== 0 && (
      <MarkdownText
        content={content}
        maxHeight={5}
        reduceHeaders
        style={{
          marginTop: '2em',
          width: '100%',
          paddingLeft: '5px',
          // paddingRight: '2%',
        }}
      />
    )}

    <div style={{ textAlign: 'center' }}>
      <LikeAndGoing eventId={id} />
    </div>

    {newss && newss.length !== 0 && (
      <React.Fragment>
        <style>
          {`
            .newssHeader {
              margin-top: 1.5em !important;
              margin-bottom: 0 !important;
            }
          `}
        </style>
        <Header as="h3" className="newssHeader">
          <Icon name="newspaper" />
          News
        </Header>
        {newss.map((news) => (
          <React.Fragment key={news.id}>
            <Divider style={{ marginTop: '1rem', marginBottom: '1rem' }} />
            {/* <p>{news.title}</p> */}
            <NewsItem key={news.id} news={news} />
          </React.Fragment>
        ))}
      </React.Fragment>
    )}
  </BlurSegment>
);

export default Event;
