// Description :
// -> The page that merges all the components

// React requirement
import React from 'react';
import ReactDOM from 'react-dom';

// Router requirement
import { BrowserRouter as Router } from 'react-router-dom';

// Apollo requirement
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import { ApolloLink, concat } from 'apollo-link';

// Datadog RUM requirement
import { datadogRum } from '@datadog/browser-rum';

import 'semantic-ui-css/semantic.min.css';

import { getToken } from '%/utils';

// Theme provider
import { DarkThemeProvider } from '%/core';

// Theme
import './theme';

// Local requirement
import Routes from './routes'; // Routes

// service worker
import * as serviceWorker from './registerServiceWorker';

import './highlight.css';

// Enable RUM monitoring
if (process.env.NODE_ENV === 'production') {
  datadogRum.init({
    applicationId: 'd4cac75e-8e7a-453c-8c55-98bf5ba3a544',
    clientToken: 'pubb127a244319ff40e368db1ca8a11783c',
    site: 'datadoghq.com',
    service: 'LinkCS',
    env: 'production',
    version: process.env.REACT_APP_VERSION_ID || undefined,
    sampleRate: 100,
    trackInteractions: false,
  });
}

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const token = getToken();
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : null,
    },
  });

  return forward(operation);
});

// Apollo Client
const httpLink = createHttpLink({ uri: process.env.REACT_APP_API_URL });
const link = concat(authMiddleware, httpLink);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV === 'development',
});

// Render
ReactDOM.render(
  <div
    id="blackout"
    className={localStorage.getItem('isBlackout') === 'false' ? '' : 'blackoutWrapper'}
  >
    <div
      id="rondade"
      className={localStorage.getItem('isRondade') === 'false' ? '' : 'rondadeWrapper'}
    >
      <div
        id="mirror"
        className={localStorage.getItem('isMirror') === 'false' ? '' : 'mirrorWrapper'}
      >
        <div
          id="invert"
          className={localStorage.getItem('isInvert') === 'false' ? '' : 'invertWrapper'}
        >
          <ApolloProvider client={client}>
            <DarkThemeProvider>
              <Router>
                <Routes />
              </Router>
            </DarkThemeProvider>
          </ApolloProvider>
        </div>
      </div>
    </div>
  </div>,
  document.getElementById('root'),
);

if (window.matchMedia('(display-mode: standalone)').matches) {
  serviceWorker.register();
} else {
  serviceWorker.unregister();
}
