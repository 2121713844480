// Description :
// -> apollo function to fetch user data

// React requirement
import React from 'react';
import { withRouter } from 'react-router';

const getApolloUrl = (getSchema, paramName) => (WrappedComponent) => {
  const withRouterComponent = ({
    match: {
      params: { [paramName]: param },
    },
    history,
    location,
    staticContext,
    ...props
  }) => {
    const OutputComponent = getSchema(param)(withRouter(WrappedComponent));
    return <OutputComponent {...props} />;
  };
  return withRouter(withRouterComponent);
};
export default getApolloUrl;
