import { loader } from 'graphql.macro';

const association = loader('./association.gql');
const associationCategories = loader('./associationCategories.gql');
const associationData = loader('./associationData.gql');
const associationPreview = loader('./associationPreview.gql');
const associationPreviewValidation = loader('./associationPreviewValidation.gql');
const compositionData = loader('./compositionData.gql');
const roleData = loader('./roleData.gql');
const roleTypeData = loader('./roleTypeData.gql');
const sectorData = loader('./sectorData.gql');
const user = loader('./user.gql');
const userContact = loader('./userContact.gql');
const userData = loader('./userData.gql');

export {
  association,
  associationCategories,
  associationData,
  associationPreview,
  associationPreviewValidation,
  compositionData,
  roleData,
  roleTypeData,
  sectorData,
  user,
  userContact,
  userData,
};
