import React, { Component } from 'react';

import { DarkThemeContext } from '%/core';
import { Button } from 'semantic-ui-react';

class CopyButton extends Component {
  constructor(props) {
    super(props);
    this.state = { PopUpText: 'Copier' };
  }

  onLeaveEvent = () => {
    this.setState({ PopUpText: 'Copier' });
  };

  copyToClipboard = (textToCopy) => {
    const hiddenCopy = document.createElement('textarea');
    hiddenCopy.value = textToCopy;
    document.body.appendChild(hiddenCopy);
    hiddenCopy.select();
    document.execCommand('copy');
    document.body.removeChild(hiddenCopy);
    this.setState({ PopUpText: 'Copie réussie' });
  };

  render() {
    const [darktheme] = this.context;
    return (
      <Button
        basic={darktheme === 'dark'}
        inverted={darktheme === 'dark'}
        size="mini"
        icon="copy"
        style={{
          marginLeft: '5px',
          marginBottom: '5px',
          height: '1,1em',
          width: '1,1em',
        }}
        onClick={() => this.copyToClipboard(this.props.textToCopy)}
        data-tooltip={this.state.PopUpText}
        data-inverted=""
        data-position="top right"
        onMouseLeave={this.onLeaveEvent}
        width="150px"
        type="button"
      />
    );
  }
}
CopyButton.contextType = DarkThemeContext;

export default CopyButton;
