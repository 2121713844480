// Description :
// -> Defines the component NewsItem

// React requirement
import React from 'react';
import { Only } from '@blocz/react-responsive';

import { Photo, Line, Column, ErrorBlock, MarkdownText, Header } from '%/core';

import NewsItemLoading from './NewsItem.loading';

const formatDate = (date) => {
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC',
  };

  return date.toLocaleDateString('fr-FR', options);
};

const NewsItem = (props) => {
  if (props.loading) {
    return <NewsItemLoading />;
  }
  if (props.error) return <ErrorBlock message={props.error.message} />;

  if (
    props.news.picture === '' ||
    props.news.picture === `${process.env.PUBLIC_URL}/images/placeholder.png`
  ) {
    return (
      <React.Fragment>
        <Line
          style={{
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}
        >
          <Header size="small" style={{ whiteSpace: 'normal' }}>
            <span style={{ whiteSpace: 'normal', tableLayout: 'fixe' }}>
              {props.news.tags.type.length > 0
                ? `[${props.news.tags.type.join(', ')}] ${props.news.title}`
                : props.news.title}
            </span>
            <span
              style={{
                fontWeight: 'normal',
                fontStyle: 'italic',
                opacity: '0.8',
                fontSize: '13px',
                marginLeft: '0.5em',
              }}
            >
              le {formatDate(new Date(`${props.news.updatedAt}Z`))}
            </span>
          </Header>
        </Line>
        <MarkdownText
          content={props.news.content}
          maxHeight={5}
          reduceHeaders
          style={{
            marginTop: '0.6em',
            width: '100%',
            textAlign: 'justify',
          }}
        />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Line
        style={{
          alignItems: 'flex-start',
          justifyContent: 'space-between',
        }}
      >
        <Header size="medium" style={{ whiteSpace: 'normal' }}>
          <span style={{ whiteSpace: 'normal', tableLayout: 'fixe' }}>
            {props.news.tags.type.length > 0
              ? `[${props.news.tags.type.join(', ')}] ${props.news.title}`
              : props.news.title}
          </span>
          <span
            style={{
              fontWeight: 'normal',
              fontStyle: 'italic',
              opacity: '0.8',
              fontSize: '13px',
              marginLeft: '0.7em',
            }}
          >
            le {formatDate(new Date(`${props.news.updatedAt}Z`))}
          </span>
        </Header>
      </Line>
      <Line style={{ alignItems: 'flex-start', flexWrap: 'wrap', marginTop: '1rem' }}>
        <Only on="smDown">
          <Column style={{ flex: 5, alignItems: 'flex-start' }}>
            <Photo
              rounded
              name={`picture of ${props.news.title}`}
              src={props.news.picture || `${process.env.PUBLIC_URL}/images/placeholder.png`}
              maxWidth="16rem"
              style={{ maxWidth: '100%' }}
            />
          </Column>
        </Only>
        <Only on="mdUp">
          <Column style={{ flex: 5, alignItems: 'flex-start', marginLeft: '0.5rem' }}>
            <Photo
              rounded
              name={`picture of ${props.news.title}`}
              src={props.news.picture || `${process.env.PUBLIC_URL}/images/placeholder.png`}
              maxWidth="16rem"
              style={{ maxWidth: '100%' }}
            />
          </Column>
        </Only>
        <Column style={{ flex: 15, alignItems: 'flex-start' }}>
          <MarkdownText
            content={props.news.content}
            maxHeight={6}
            reduceHeaders
            style={{
              width: '100%',
              marginLeft: '1em',
            }}
          />
        </Column>
      </Line>
    </React.Fragment>
  );
};

export default NewsItem;
