// This file exports a function that validate the creation of an association
//   -> if the association is not valid, fill it with its errors and returns it
//   -> else if the association is valid, it can be sent to the back, and returns null

// React requirement
import { isUrl } from 'check-valid-url';

export default (association, options) => {
  let validate = true;
  const correction = { ...association };
  const { name, website, composition, categories } = correction;
  const { roleTypes, cats } = options;
  const uniqueRoles = [];
  const uniqueRoleTypesIds = roleTypes
    .filter((roleType) => roleType.isUniqueInComposition)
    .map((roleType) => ({ id: roleType.id, name: roleType.name }));

  correction.errors = [];
  if (name === '') {
    correction.errors.push({
      name: 'name',
      message: "Le nom de l'association doit être renseigné",
    });
  }
  if (website && !isUrl(website)) {
    correction.errors.push({
      name: 'website',
      message: "L'adresse de site web renseignée n'est pas une URL valide",
    });
  }
  if (categories.length === 0) {
    correction.errors.push({
      name: 'categories',
      message: 'Vous devez sélectionner au moins une catégorie',
    });
  }
  categories.forEach((category) => {
    if (cats && cats.find((cat) => category === cat.id) === undefined) {
      correction.errors.push({
        name: 'categories',
        message: 'Une ou plusieurs catégories sélectionnées ne sont pas reconnues',
      });
    }
  });
  validate = validate && correction.errors.length === 0;

  correction.composition.errors = [];
  if (composition.label === '') {
    correction.composition.errors.push({
      name: 'label',
      message: 'Le nom de la composition doit être renseigné',
    });
  }
  validate = validate && correction.composition.errors.length === 0;

  correction.composition.sectors = correction.composition.sectors.map((inSector) => {
    const sector = { ...inSector };
    sector.errors = [];
    if (sector.name === '') {
      sector.errors.push({
        name: 'name',
        message: 'Le nom du secteur doit être renseigné',
      });
    }
    if (sector.roles.length === 0) {
      sector.errors.push({
        name: 'empty',
        message: 'Le secteur ne peut pas être vide',
      });
    }
    validate = validate && sector.errors.length === 0;

    sector.roles = sector.roles.map((inRole) => {
      const role = { ...inRole };
      role.errors = [];
      if (role.label === '') {
        role.errors.push({
          name: 'label',
          message: 'Le nom du rôle doit être renseigné',
        });
      }
      if (role.userId === 0) {
        role.errors.push({
          name: 'user',
          message: 'Le rôle doit être associé à un utilisateur',
        });
      }
      if (roleTypes && roleTypes.find((rT) => rT.id === role.roleTypeId) === undefined) {
        role.errors.push({
          name: 'roleType',
          message: "Le type de rôle sélectionné n'est pas reconnu",
        });
      }
      validate = validate && role.errors.length === 0;

      if (uniqueRoleTypesIds.map((roleType) => roleType.id).includes(role.roleTypeId)) {
        uniqueRoles.push(role);
      }
      return role;
    });

    return sector;
  });

  const minPriority = Math.min(...correction.composition.sectors.map((sec) => sec.priority)) - 1;
  if (minPriority <= 0) {
    correction.composition.sectors = correction.composition.sectors.map((sector) => ({
      ...sector,
      priority: sector.priority - minPriority,
    }));
  }

  for (let i = 0; i < uniqueRoleTypesIds.length; i += 1) {
    const roles = uniqueRoles.filter((role) => role.roleTypeId === uniqueRoleTypesIds[i].id);
    if (roles.length > 1) {
      roles.forEach((role) => {
        role.errors.push({
          name: 'roleType',
          message: `Le type de rôle ${uniqueRoleTypesIds[i].name} doit être unique`,
        });
      });
      validate = false;
    }
  }

  return validate ? null : { ...correction, validate };
};
