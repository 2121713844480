import React from 'react';
import { graphql, ApolloConsumer } from 'react-apollo';
import compose from 'lodash.flowright';

import { loader } from 'graphql.macro';

import LikeAndGoing from './LikeAndGoing.js';

const createLike = loader('./createLike.gql');
const updateLike = loader('./updateLike.gql');
const deleteLike = loader('./deleteLike.gql');
const searchLike = loader('./searchLike.gql');
const getUser = loader('./getUser.gql');
const searchUserLike = loader('./searchUserLike.gql');

export default compose(
  graphql(getUser, {
    props: ({ data: { me, loading, ...p }, ...props }) => ({
      user: me,
      loadingUser: loading,
      data: { ...p },
      ...props,
    }),
  }),
  graphql(createLike, {
    props: ({ mutate }) => ({
      createLike: (like) => mutate({ variables: { like } }),
    }),
  }),
  graphql(updateLike, {
    props: ({ mutate }) => ({
      updateLike: (id, like) => mutate({ variables: { id, like } }),
    }),
  }),
  graphql(deleteLike, {
    props: ({ mutate }) => ({
      deleteLike: (id) => mutate({ variables: { id } }),
    }),
  }),
  graphql(searchLike, {
    name: 'likes',
    options: (props) => ({
      variables: {
        event: props.eventId,
        limit: 10000,
      },
      fetchPolicy: 'no-cache',
    }),
  }),
  graphql(searchUserLike, {
    name: 'isLiked',
    skip: ({ user }) => !user,
    options: ({ user, eventId }) => ({
      variables: {
        user: user.id,
        event: eventId,
      },
      fetchPolicy: 'no-cache',
    }),
  }),
)((props) => <ApolloConsumer>{() => <LikeAndGoing {...props} />}</ApolloConsumer>);
