// Description :
// -> Called to say if the user isConnected or not

export const oauthToken = 'oauthToken';
const savedUrl = 'savedUrl';

export const getToken = () => window.localStorage.getItem(oauthToken);

export const getTokenPayload = () => {
  const token = getToken();
  if (token !== null) {
    const tokenPayload = JSON.parse(atob(token.split('.')[1]));
    tokenPayload.iat = parseInt(tokenPayload.iat, 10);
    tokenPayload.exp = parseInt(tokenPayload.exp, 10);
    tokenPayload.client.id = parseInt(tokenPayload.client.id, 10);
    tokenPayload.user.id = parseInt(tokenPayload.user.id, 10);
    return tokenPayload;
  }
  return null;
};

export const clearToken = () => {
  localStorage.removeItem(oauthToken);
};

export const hasToken = () => {
  const tokenPayload = getTokenPayload();
  if (!tokenPayload) {
    return false;
  }
  return true;
};

export const isConnected = () => {
  if (!hasToken()) {
    return false;
  }
  const tokenPayload = getTokenPayload();
  const expiresAt = tokenPayload.exp * 1000;
  const dateNow = new Date();
  if (expiresAt > dateNow.getTime()) {
    return true;
  }
  return false;
};

export const getLogin = () => {
  const tokenPayload = getTokenPayload();
  if (!tokenPayload) {
    return '';
  }
  return tokenPayload.user.login;
};

export const isAdmin = () => {
  const tokenPayload = getTokenPayload();
  if (!tokenPayload || tokenPayload.user.roles.indexOf('linkcs_admin') === -1) {
    return false;
  }
  return true;
};

export const setToken = (token) => {
  window.localStorage.setItem(oauthToken, token);
};

export const checkToken = (token) => {
  const multipart = token.split('.');
  if (multipart.length !== 3) {
    return false;
  }
  // Do a try catch because if the token isn't a JSON,
  // JSON.parse will crash
  try {
    const {
      client,
      encryptedAccessToken,
      encryptedRefreshToken,
      exp,
      iat,
      scope,
      user,
    } = JSON.parse(atob(multipart[1]));
    if (
      !client ||
      typeof encryptedAccessToken !== 'string' ||
      typeof encryptedRefreshToken !== 'string' ||
      (typeof exp !== 'string' && typeof exp !== 'number') ||
      (typeof iat !== 'string' && typeof iat !== 'number') ||
      typeof scope !== 'string' ||
      !user
    ) {
      return false;
    }
    const { clientId, id } = client;
    const { id: userId, login, roles } = user;
    if (
      typeof clientId !== 'string' ||
      (typeof id !== 'string' && typeof id !== 'number') ||
      (typeof userId !== 'string' && typeof userId !== 'number') ||
      typeof login !== 'string' ||
      typeof roles !== 'string'
    ) {
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
};

export const getUrl = () => window.localStorage.getItem(savedUrl) || '/';

export const setUrl = (url) => window.localStorage.setItem(savedUrl, url);

export const clearUrl = () => window.localStorage.removeItem(savedUrl);
