import React from 'react';
import { Card } from 'semantic-ui-react';

const Grid = ({ itemsPerRow = 6, children, ...props }) => {
  if (props.size === 'sm') {
    return (
      <Card.Group doubling centered itemsPerRow={itemsPerRow - 1} className="centered">
        {children}
      </Card.Group>
    );
  }
  return (
    <Card.Group doubling centered itemsPerRow={itemsPerRow} className="centered">
      {children}
    </Card.Group>
  );
};

export default Grid;
