// Description :
// -> apollo function to fetch association data

// React requirement
import React from 'react';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

// Fragments requirement
import { includeFragment } from '%/utils';
import * as fragments from '%/fragments';

const withAssoTerm = (term) => {
  const searchAsso = gql`
    query {
      searchAsso: searchAssociations(term: "${term}", old: false) {
        type
        ...associationPreview
      }
    }
  `;
  includeFragment(searchAsso, fragments.associationPreview);
  return graphql(searchAsso);
};

const apolloGetSearchAsso = (WrappedComponent) => {
  const ApolloComponent = ({ termSearch, limit, ...props }) => {
    if (termSearch) {
      const OutputComponent = withAssoTerm(termSearch)(WrappedComponent);
      return <OutputComponent {...props} />;
    }
    return <WrappedComponent {...props} data={{}} />;
  };
  return ApolloComponent;
};

export default apolloGetSearchAsso;
