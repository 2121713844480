import React from 'react';

import { Only } from '@blocz/react-responsive';
import { Link } from 'react-router-dom';

import { LikeAndGoing } from '%/core';

const genEventOverlayTop = (rgbColor) => ({ style = {}, ...props }) => (
  <div
    {...props}
    style={{
      color: 'white',
      textAlign: 'center',
      fontWeight: 700,
      lineHeight: '1.4em',
      fontSize: '1.4em',
      backgroundColor: `rgba(${rgbColor}, 0.5)`,
      width: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      padding: '5px',
      border: '0',
      zIndex: '1 !important',
      ...style,
    }}
  />
);

const EventBottom = ({ style = {}, ...props }) => (
  <div
    {...props}
    style={{
      color: 'white',
      fontWeight: 700,
      fontSize: '1.4em',
      lineHeight: '1.8em',
      height: '2em',
      margin: 0,
      padding: '0.1em',
      borderBottomLeftRadius: '0.3em',
      borderBottomRightRadius: '0.3em',
      position: 'relative',
      ...style,
    }}
  />
);

const EventContainer = ({ style = {}, url, ratio, ...props }) => (
  <div
    {...props}
    style={{
      border: 0,
      position: 'relative',
      backgroundImage: `url(${url})`,
      overflow: 'hidden',
      borderTopLeftRadius: '0.3em',
      borderTopRightRadius: '0.3em',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      paddingBottom: `${100 / ratio}%`,
      ...style,
    }}
  />
);

const TileEvent = ({ event, loading, color, ratio }) => {
  const decimalColor = parseInt(color.substr(1), 16);
  /* eslint-disable no-bitwise */
  const rgbColor = [
    (decimalColor >> 16) & 0xff,
    (decimalColor >> 8) & 0xff,
    decimalColor & 0xff,
  ].join();
  const EventOverlayTop = genEventOverlayTop(rgbColor);
  if (loading) {
    return (
      <React.Fragment>
        <EventContainer url={`${process.env.PUBLIC_URL}/images/event_default.jpg`} ratio={ratio}>
          <EventOverlayTop active>
            <span>Chargement...</span>
          </EventOverlayTop>
        </EventContainer>
        <EventBottom style={{ backgroundColor: color }}>
          <span>-</span>
          <span
            style={{
              position: 'relative',
            }}
          >
            <span
              style={{
                fontSize: '0.6em',
                lineHeight: '0.6em',
                position: 'absolute',
                top: '0',
              }}
            >
              H
            </span>
          </span>
          <span
            style={{
              fontSize: '0.7em',
              lineHeight: '0.7em',
              position: 'absolute',
              left: '1.1em',
              bottom: '0.8em',
            }}
          >
            -
          </span>
          <span
            style={{
              position: 'absolute',
              fontSize: '0.8em',
              lineHeight: '0.8em',
              textTransform: 'uppercase',
              top: '0.4em',
              right: '5px',
            }}
          >
            ?
          </span>
          <span
            style={{
              fontSize: '0.6em',
              lineHeight: '0.6em',
              position: 'absolute',
              bottom: '0.5em',
              right: '5px',
            }}
          >
            ?
          </span>
        </EventBottom>
      </React.Fragment>
    );
  }

  const date = new Date(`${event.beginningDate}Z`);
  const heure = date.getUTCHours();
  const minutes = date.getUTCMinutes();

  const getDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getUTCDate() + 1);

    if (date.toUTCString().substr(0, 16) === today.toUTCString().substr(0, 16)) {
      return "Aujourd'hui";
    }
    if (date.toUTCString().substr(0, 16) === tomorrow.toUTCString().substr(0, 16)) {
      return 'Demain';
    }
    if (date.getTime() < Date.now()) {
      return 'En cours';
    }
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      timeZone: 'UTC',
    };

    return date
      .toLocaleDateString('fr-FR', options)
      .replace(/(\s\w|^[a-z])/g, (l) => l.toUpperCase())
      .replace(/ \d{4}/g, '');
  };

  return (
    <Link to={`/event/${event.id}`} title={event.title}>
      <EventContainer
        url={event.picture ? event.picture : `${process.env.PUBLIC_URL}/images/event_default.jpg`}
        ratio={ratio}
      >
        <EventOverlayTop active>
          <LikeAndGoing eventId={event.id} tile />
          <span>{getDate()}</span>
        </EventOverlayTop>
      </EventContainer>
      <EventBottom style={{ backgroundColor: color }}>
        <span>{heure}</span>
        <span
          style={{
            position: 'relative',
          }}
        >
          <span
            style={{
              fontSize: '0.6em',
              lineHeight: '0.6em',
              position: 'absolute',
              top: '0',
            }}
          >
            H
          </span>
          {minutes !== 0 && (
            <span
              style={{
                fontSize: '0.7em',
                lineHeight: '0.7em',
                position: 'absolute',
                bottom: '0.25em',
              }}
            >
              {minutes}
            </span>
          )}
        </span>
        <span
          style={{
            position: 'absolute',
            fontSize: '0.8em',
            lineHeight: '0.8em',
            textTransform: 'uppercase',
            top: '0.4em',
            right: '5px',
          }}
        >
          <Only on="xsDown">
            {event.title.length > 22 ? `${event.title.substring(0, 22)}...` : event.title}
          </Only>
          <Only on="sm">
            {event.title.length > 35 ? `${event.title.substring(0, 35)}...` : event.title}
          </Only>
          <Only on="mdUp">
            {event.title.length > 16 ? `${event.title.substring(0, 16)}...` : event.title}
          </Only>
        </span>
        <span
          style={{
            fontSize: '0.6em',
            lineHeight: '0.6em',
            position: 'absolute',
            bottom: '0.5em',
            right: '5px',
          }}
        >
          <Only on="xsDown">
            {`${
              event.association.name.length > 20
                ? `${event.association.name.substring(0, 20)}...`
                : event.association.name
            } | ${
              event.location.length > 20 ? `${event.location.substring(0, 20)}...` : event.location
            }`}
          </Only>
          <Only on="sm">
            {`${
              event.association.name.length > 30
                ? `${event.association.name.substring(0, 30)}...`
                : event.association.name
            } | ${
              event.location.length > 30 ? `${event.location.substring(0, 30)}...` : event.location
            }`}
          </Only>
          <Only on="mdUp">
            {`${
              event.association.name.length > 12
                ? `${event.association.name.substring(0, 12)}...`
                : event.association.name
            } | ${
              event.location.length > 12 ? `${event.location.substring(0, 12)}...` : event.location
            }`}
          </Only>
        </span>
      </EventBottom>
    </Link>
  );
};

export default TileEvent;
